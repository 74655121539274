<template>
    <div>
        <b-button variant="success" size="sm" id="btn-add-criteria" v-b-modal.modal-add-criteria @click="resetForm()">
            <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
        </b-button>
        <b-modal id="modal-add-criteria" :title="formTitle" @ok="handleOkay" @close="resetForm()">
            <b-form>
                <b-form-group
                    id="input-group-subject"
                    label="Thema der Frage"
                    label-for="input-criteria-subject"
                    description="Nennen Sie einen kurzen Oberbegriff für dieses Kriterium."
                >
                    <b-form-input
                        id="input-criteria-subject"
                        v-model="form.subject"
                        type="text"
                        placeholder="Thema..."
                        required
                    >
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-display"
                    label="Anzeigetext der Frage"
                    label-for="input-criteria-display"
                    description="Geben Sie den Anzeigetext für den Besucher ein."
                >
                    <b-form-input
                        id="input-criteria-subject"
                        v-model="form.display_text"
                        type="text"
                        placeholder="Anzeigetext..."
                        required
                    >
                    </b-form-input>
                </b-form-group>
                <b-form-group
                    id="input-group-is-text"
                    label-for="input-criteria-is-text"
                >
                    <b-form-checkbox v-model="form.is_text" switch>
                        Textfeld statt Punktebewertung?
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                    id="input-group-is-active"
                >
                    <b-form-checkbox v-model="form.is_active" switch>
                        In aktuellen Bewertungen anzeigen?
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                    :label="'Position im Fragebogen: ' + form.order"
                >
                    <div class="range-slider--wrapper">
                        <span>{{ formOrderMin }}</span>
                        <b-input
                            type="range"
                            id="input-criteria-order"
                            :min="formOrderMin"
                            :max="formOrderMax"
                            required
                            v-model="form.order"
                        ></b-input>
                        <span>{{ formOrderMax }}</span>
                    </div>
                </b-form-group>
            </b-form>
            <template #modal-footer="{ok, cancel}">
                <b-button variant="danger" @click="() => {cancel();resetForm();}">
                    Abbrechen
                </b-button>

                <b-button variant="success" @click="ok()">
                    Speichern
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "../../eventBus";

export default {
    name: "ReviewCriteriaForm",
    data() {
        return {
            form: {
                subject: "",
                display_text: "",
                is_text: false,
                is_active: false,
                order: 0
            },
            formOrderMin:0,
            formOrderMax:15,
            edit:false
        }
    },
    mounted() {
        eventBus.$on("REVIEW_CRITERIA_FORM_MODAL_OPEN", this.onRequestEditForm.bind(this));
    },
    computed: {
        ...mapGetters(["getCriteria"]),
        formTitle(){
            return "Bewertungskriterium " + (this.edit ? "bearbeiten" : "anlegen");
        }
    },
    methods: {
        ...mapActions(["fetchCriteria", "createCriteria", "updateCriteria"]),
        handleSubmit() {
            if(!this.edit)
            {
                this.createCriteria(this.form);
            }
            else
            {
                this.updateCriteria(this.form);
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-add-criteria");
                this.resetForm();
            });
        },
        resetForm()
        {
            this.edit = false;

            delete this.form.id;

            this.form.subject = "";
            this.form.display_text = "";
            this.form.is_text = false;
            this.form.is_active = false;
            this.form.order = 0;
        },
        handleOkay(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.handleSubmit();
        },
        onRequestEditForm(event){
            this.edit = true;
            this.$bvModal.show("modal-add-criteria");
            this.form.subject = event.subject;
            this.form.display_text = event.display_text;
            this.form.is_text = event.is_text;
            this.form.is_active = event.is_active;
            this.form.order = event.order;
            this.form.id = event.id;
        }
    },
}
</script>

<style scoped lang="scss">
#btn-add-criteria{
    position:absolute;
    right:0;
    top:15px;
    width:40px;
    height:40px;
}

#modal-add-criteria{
    input[type="text"],
    textarea{
        border: 1px #cecece;
        background: #f1f1f1;
        box-shadow: none;
    }
}
</style>
