import { render, staticRenderFns } from "./ReviewCriteriaListItemActions.vue?vue&type=template&id=7e501f4a&scoped=true&"
import script from "./ReviewCriteriaListItemActions.vue?vue&type=script&lang=js&"
export * from "./ReviewCriteriaListItemActions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e501f4a",
  null
  
)

export default component.exports