import { ToastNotification } from "../exceptionHandler";
import {eventBus} from "../../eventBus";

const state = {
    consultants: [],
    selectedConsultant: {}
}

export const getters = {
    getConsultants: state => state.consultants,
    getSelectedConsultant: state => state.selectedConsultant,
    getConsultantById: state => (id) => {
        return state.consultants.filter(el => el.consultant_id === id)[0];
    },
    areConsultantsAvailableOnDay: state => (date) => {
        let available = false;

        state.consultants.forEach(el => {
            if (el.absence.length > 0) { // if there are absence entries
                let filterRes = el.absence.filter(elem => formatDate(elem.date) === formatDate(date)); // filter entries for given date

                if (filterRes.length === 0) { // if there are no entries for given date
                    available = true; // there is a consultant available...
                }
                return;
            }
            available = true;
        });

        return available;
    },
    getAvailableConsultantsForDate: (state,getters) => (date) => {
        return state.consultants.filter(fConsultant => {
            return fConsultant.absence.filter(fAbsence => {
                return fAbsence.date === formatDate(date)
                    && fAbsence.timeslots === null;
            }).length < 1;
        });
    },
    getAvailableConsultantsForTimeslotOnDate: (state,getters,rootState,rootGetters)  => (date,timeslotValue) => {

        return getters.getAvailableConsultantsForDate(date).filter(fConsultant => {
            const hasNoAbsenceOnTimeslot = fConsultant.absence.filter(fAbsence => {
                // is absent on timeslot
                return fAbsence.date === window.formatDate(date) && fAbsence.timeslots.includes(timeslotValue);
            }).length < 1; // there is NO absence data for the given timeslot value AND date

            const hasNoAppointmentOnTimeslot = rootGetters.getAppointmentsByConsultantId(fConsultant.consultant_id).filter(fAppointment => {

                return fAppointment.time === timeslotValue && fAppointment.date === window.formatDate(date);
            }).length < 1;

            return hasNoAbsenceOnTimeslot && hasNoAppointmentOnTimeslot;
        });

    }
}

const actions = {

    fetchConsultants({commit, getters, rootState, rootGetters}, { vm }) {
        window.axios.get("/consultants")
            .then(res => {
                commit("setConsultants", res.data.data);
                    if(rootGetters.isAuthenticated)
                    {
                        ToastNotification.makeSuccessToast(vm, "Beraterdaten erfolgreich geladen!");
                    }
            })
            .catch(err => ToastNotification.makeErrorToast(vm, err));
    },
    createConsultant({ commit, getters }, { vm }) {
        window.axios.post("/consultants", getters.getSelectedConsultant)
            .then(res => {
                commit("addConsultant", res.data.data);
                ToastNotification.makeSuccessToast(vm, "Der Berater wurde erfolgreich angelegt.")
            })
            .catch(err => ToastNotification.makeErrorToast(vm, err));
    },
    updateConsultant({ commit, getters,rootState,rootGetters }, { vm }) {
        let consultant = Object.assign({}, getters.getSelectedConsultant);
        delete consultant.consultant_id;
        delete consultant.absence;
        window.axios.put("/consultants/" + getters.getSelectedConsultant.consultant_id,
            consultant)
            .then(res => {
                commit("updateConsultant", res.data.data);
                if(rootGetters.isAuthenticated)
                {
                    ToastNotification.makeSuccessToast(vm, "Berater erfolgreich bearbeitet.")
                }
            })
            .catch(err => ToastNotification.makeErrorToast(vm, err));
    },
    deleteConsultant({ commit }, { id, vm }) {
        window.axios.delete("/consultants/" + id)
            .then(() => commit("deleteConsultant", id))
            .catch(err => ToastNotification.makeErrorToast(vm, err));
    },
    addAbsenceDayToConsultant({ commit, dispatch }, { vm, day, id, s_cb =null, f_cb=null }) {
        window.axios.post("/consultants/" + id + "/absence", day)
            .then((resp) => {
                if(_.isFunction(s_cb))
                {
                    s_cb(resp);
                }
                dispatch("fetchConsultants", { vm });
                return ToastNotification.makeSuccessToast(vm, "Der Berater wurde erfolgreich als ABWESEND eingetragen!")
            })
            .catch(err => {
                if(_.isFunction(f_cb))
                {
                    f_cb(err)
                }
                ToastNotification.makeErrorToast(vm, err);
            })
    },
    updateAbsenceDay({commit, dispatch}, {vm,consultantId,absenceId, day, s_cb, f_cb}){
      window.axios.put("/consultants/" + consultantId + "/absence/" + absenceId,day)
          .then(response => {
              if(_.isFunction(s_cb))
              {
                  s_cb(response);
              }
              dispatch("fetchConsultants",{vm});
          })
          .catch(err => {
             if(_.isFunction(f_cb)){
                 f_cb(err);
             }
          });
    },
    removeAbsenceDayFromConsultant({ commit, dispatch }, { vm, consultantId, absenceId }) {
        window.axios.delete("/consultants/" + consultantId + "/absence/" + absenceId)
            .then(res => {
                dispatch("fetchConsultants", { vm });
                return ToastNotification.makeSuccessToast(vm, "Der Berater wurde erfolgreich als VERFÜGBAR eingetragen!")
            })
            .catch(err => ToastNotification.makeErrorToast(vm, err))
    }
}

const mutations = {
    setConsultants: (state, consultants) => state.consultants = consultants,
    setSelectedConsultant: (state, consultant) => {
        state.selectedConsultant = consultant
        eventBus.$emit("STORE_SET_SELECTED_CONSULTANT", consultant);
    },
    addConsultant: (state, consultant) => state.consultants.push(consultant),
    updateConsultant: (state, consultant) => {
        const index = state.consultants.findIndex(el => el.consultant_id === consultant.consultant_id);

        if (index !== -1) {
            state.consultants.splice(index, 1, consultant);
        }
    },
    deleteConsultant: (state, consultant_id) => state.consultants = state.consultants.filter(el => el.consultant_id !== consultant_id)
}

export default {
    state,
    getters,
    actions,
    mutations
}
