<template>
    <div>
        <div
            class="absence-tile"
            v-for="consultant in getConsultants"
            :key="consultant.consultant_id"
        >
            <b-form-checkbox
                switch
                :checked="isAbsent(consultant.consultant_id)"
                size="sm"
                @change="switchAbsence(consultant.consultant_id)"
            >{{
                    (consultant.last_name.length > 7 ? consultant.last_name.substr(0,8) : consultant.last_name)
                }}
            </b-form-checkbox
            >
            <b-icon-gear-fill class="cfg-button" @click="onConfigBtnClick(consultant.consultant_id)"></b-icon-gear-fill>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    name: "AbsenceTile",
    props: {
        date: {
            type: Date,
            required: true
        }
    },
    data: () => {
        return {
        };
    },
    computed: {
        ...mapGetters(["getConsultants", "getConsultantById"]),
        isAbsent() {
            let vm = this;
            return id => {
                let val = this.getConsultantById(id).absence.filter(
                    el => el.date === formatDate(vm.date)
                );
                return val.length > 0;
            };
        }
    },
    methods: {
        ...mapActions([
            "addAbsenceDayToConsultant",
            "removeAbsenceDayFromConsultant",
        ]),
        ...mapMutations([
           "setSelectedConsultant",
            "setSelectedDate"
        ]),
        onConfigBtnClick(consultantId){
            this.setSelectedDate(this.date);
            this.setSelectedConsultant( this.getConsultantById(consultantId));
            this.$bvModal.show("timeslot_cfg_modal");
        },
        switchAbsence(id) {
            let consultant = {...this.getConsultantById(id)};
            let absence = consultant.absence.filter(
                el => el.date === formatDate(this.date)
            )[0];
            if (absence !== undefined) {
                this.removeAbsenceDayFromConsultant({
                    vm: this,
                    absenceId: absence.absence_day_id,
                    consultantId: id
                });
            } else {
                this.addAbsenceDayToConsultant({
                    vm: this,
                    day: {date: formatDate(this.date), timeslots: null},
                    id: id
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.absence-tile {
    border-radius: 3px 4px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    margin: 5px 0;
    cursor: pointer;
    color: #000;

    .cfg-button {
        border: 1px solid rgba(0,0,0,0.3);
        padding:2px;
        border-radius: 9px;
        &:hover{
            box-shadow: 0px 0px 3px rgba(0,0,0,0.5);
            cursor: pointer;
        }
    }
}
</style>
