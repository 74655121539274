<template>
    <b-container class="mb-4">
        <b-row class="calendar-head p-2 text-dark my-3">
            <b-col cols="2" align-self="center">
                <div
                    @click="switchMonth(-1)"
                    class="calendar-button pointer"
                    v-if="this.isMonthSwitchingAllowed(-1)"
                >
                    <b-icon-arrow-left/>
                </div>
            </b-col>
            <b-col cols="8" class="text-center" align-self="center">
                <div>
                    <h3>{{ monthName }}</h3>
                </div>
            </b-col>
            <b-col cols="2" align-self="center" class="text-right">
                <div
                    @click="switchMonth(1)"
                    class="calendar-button pointer"
                    v-if="this.isMonthSwitchingAllowed(1)"
                >
                    <b-icon-arrow-right/>
                </div>
            </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
            <b-col align-self="center" class="no-padding">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>Mo</th>
                        <th>Di</th>
                        <th>Mi</th>
                        <th>Do</th>
                        <th>Fr</th>
                        <th>Sa</th>
                        <th>So</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in generateCalendarSheet" :key="index">
                        <td
                            :class="[
                  {
                    'inactive-month':
                      col.date.getMonth() !== getFrom.getMonth(),
                  },
                ]"
                            v-for="(col, ind) in row"
                            :key="ind"
                        >
                            <Day
                                :dayInfo="col"
                                :clickable="col.date.getMonth() === getFrom.getMonth()"
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
        <TimeslotAbsenceModal/>
        <ShowReviewsModal></ShowReviewsModal>
    </b-container>
</template>

<script>
import Day from "./Day.vue";
import TimeslotAbsenceModal from "../consultants/TimeslotAbsenceModal";

import {mapActions, mapGetters, mapMutations} from "vuex";
import {eventBus} from "../../eventBus";
import ShowReviewsModal from "../Review/ShowReviewsModal";

export default {
    name: "Calendar",
    components: {
        Day,
        TimeslotAbsenceModal,
        ShowReviewsModal
    },
    data() {
        return {
            NOW: new Date(),
            now: {},
            MONTH_NAME: [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember",
            ],
        };
    },
    computed: {
        ...mapGetters([
            "getFrom",
            "getTo",
            "isAuthenticated",
            "getAppointments",
            "getTimeslotsForDate",
            "getAvailableConsultantsForTimeslotOnDate",
            "getConsultants",
            "getCalendarData",
            "getCalendarDataForDate"
        ]),

        generateCalendarSheet() {
            let date = this.getFrom;
            date.setDate(1);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            date.setMilliseconds(0);
            date = this.getMonday(date);

            const sheet = [];
            let row = [];
            let dateObj;

            let filter = (el) => {
                let d = new Date(el.date);
                return (
                    d.getMonth() === dateObj.getMonth() &&
                    d.getDate() === dateObj.getDate() &&
                    d.getFullYear() === dateObj.getFullYear()
                );
            };
            for (let i = 0; i < 6; i++) {
                for (let j = 0; j < 7; j++) {
                    dateObj = new Date(date);
                    let objForDay = {
                        date: dateObj,
                    };
                    let appointmentData;
                    //Nach den Terminen schauen und filtern
                    if (this.isAuthenticated && this.$route.name !== "absence") {
                        objForDay.appointments = this.getAppointments.filter(filter);
                        const unverifiedAppointments = objForDay.appointments.filter(appointment => {
                            return !appointment.user_confirmed;
                        });
                        let incompleteAppCount = objForDay.appointments.filter(
                            (el) => el.consultant === null
                        ).length;
                        if (objForDay.appointments.length > 0 && incompleteAppCount > 0) {
                            objForDay.title =
                                objForDay.appointments.length +
                                " gebucht<br>" +
                                incompleteAppCount +
                                " unbearbeitet";
                            objForDay.variant = 1;
                        } else if (objForDay.appointments.length === 0) {
                            objForDay.title = "Keine Termine gebucht";
                            objForDay.variant = 2;
                        } else if (
                            objForDay.appointments.length > 0 &&
                            incompleteAppCount <= 0
                        ) {
                            objForDay.title = "Alles bearbeitet!";
                            objForDay.variant = 0;
                        }
                        if (unverifiedAppointments.length > 0) {
                            objForDay.variant = objForDay.variant === 0 ? 1 : objForDay.variant;
                            objForDay.title += "<br>" + unverifiedAppointments.length + " unbestätigt";
                        }
                    } else {

                        if (this.isDateInPast(objForDay.date)) {
                            objForDay.variant = 2;
                        } else {
                            objForDay.variant =
                                this.getVariantForAppointTile(date);
                        }
                    }
                    row.push(objForDay);
                    date.setDate(date.getDate() + 1);
                }
                sheet.push(row);
                row = [];
            }
            return sheet;
        },

        from: {
            get() {
                return formatDate(this.getFrom);
            },
            set(value) {
                this.setFrom(new Date(value));
                this.fetchEvents({vm: this});
            },
        },

        to: {
            get() {
                return formatDate(this.getTo);
            },
            set(value) {
                this.setTo(new Date(value));
                this.fetchEvents({vm: this});
            },
        },

        monthName() {
            return (
                this.MONTH_NAME[this.now.getMonth()] + " " + this.now.getFullYear()
            );
        },
    },

    methods: {
        ...mapActions([
            "fetchAppointmentsForUser",
            "fetchConsultants",
        ]),
        ...mapMutations(["setFrom", "setTo"]),
        isDateInPast(date) {
            let now = new Date();
            now.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);
            return date < now;
        },
        getMonday(date) {
            date = new Date(date);
            while (date.getDay() !== 1) {
                date.setDate(date.getDate() - 1);
            }
            return date;
        },
        isMonthSwitchingAllowed(summand) {
            let from = new Date(this.getFrom);
            let now = new Date();
            from.setMonth(from.getMonth() + summand);
            let nowMax = new Date();
            nowMax.setMonth(now.getMonth() + 2);
            let nowPlus = new Date();
            nowPlus.setMonth(now.getMonth() + 1);
            return (
                from.getMonth() === now.getMonth() ||
                from.getMonth() === nowMax.getMonth() ||
                from.getMonth() === nowPlus.getMonth() ||
                this.isAuthenticated
            );
        },
        switchMonth(summand) {
            if (!this.isMonthSwitchingAllowed(summand)) {
                return;
            }
            let from = this.getFrom;
            from.setMonth(from.getMonth() + summand);
            this.setFrom(new Date(from));
            this.now = new Date(from);
            let to = new Date(from);
            to.setMonth(to.getMonth() + 1);
            to.setDate(0);
            this.setTo(to);
            this.fetchAppointmentsForUser({vm: this});

        },
        getVariantForAppointTile(date) {
            const calendarData = this.getCalendarDataForDate(date);

            let allTimeslotsOpen = true;
            let allTimeslotsClosed = true;
            if(calendarData)
            {
                for(let timeslot in calendarData.timeslots)
                {

                    if(calendarData.timeslots[timeslot].max === 0 || (calendarData.timeslots[timeslot].max > 0 && calendarData.timeslots[timeslot].available < calendarData.timeslots[timeslot].max))
                    {
                        allTimeslotsOpen = false;
                    }
                    if(calendarData.timeslots[timeslot].max !== 0 && calendarData.timeslots[timeslot].available > 0)
                    {
                        allTimeslotsClosed = false;
                    }
                }
            }

            return (allTimeslotsOpen ? 0 : allTimeslotsClosed ? 2: 1);
        },
        onRefreshBtnClickedListener() {
            this.fetchAppointmentsForUser({vm: this});
        }
    },
    created() {
        eventBus.$on('REFRESH_BTN_CLICKED', this.onRefreshBtnClickedListener);

        if (Object.keys(this.getFrom).length === 0) {
            const from = new Date();
            from.setDate(1);
            this.now = from;
            this.setFrom(from);
        }
        if (Object.keys(this.getTo).length === 0) {
            const to = new Date();
            to.setMonth(to.getMonth() + 1);
            to.setDate(0);
            this.setTo(to);
        }
        this.fetchConsultants({vm: this});
        this.fetchAppointmentsForUser({vm: this}).then(result => {
        });
    },

    beforeDestroy() {
        eventBus.$off('REFRESH_BTN_CLICKED', this.onRefreshBtnClickedListener);
    }
};
</script>
<style lang='scss' scoped>
.calendar-spinner{
    display:flex;
    justify-content: center;
    align-content: center;
    margin:auto;
    height: 400px;
}
.calendar-head {
    h2 {
        margin: 0;
    }
}

.calendar-button {
    font-size: 40px;

    &:hover {
        cursor: pointer;
        color: #999;
    }
}

.inactive-month {
    color: #a9a9a9;
}

table {
    table-layout: fixed;
}

th {
    font-size: 20px;
    text-align: center;
}

.no-padding {
    padding: 0px;
}
</style>
