<template>
    <b-row>
        <b-col cols="6" class="text-nowrap">
            <b-icon-star-fill variant="warning" v-for="n in parseInt(count)" :key="n"></b-icon-star-fill>
        </b-col>
        <b-col cols="6" class="text-nowrap">
            ({{getStarCount}}) {{calculatePercentage}}%
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: "CountingStarBar",
    props:[
        "count",
        "item"
    ],
    computed:{
        getStarCount()
        {
            return this.item.scores.filter(score => {
                return score.score === parseInt(this.count);
            }).length;
        },
        calculatePercentage()
        {
            if(this.getStarCount === 0)
            {
                return 0;
            }

            return (this.getStarCount / this.item.scores.length * 100).toFixed(1);
        }
    }
}
</script>

<style scoped>

</style>
