<template>
    <div class="day">
        <b-row class>
            <b-button v-if="reviewCount > 0" class="review-count" size="sm" @click="onClickShowReviewsBtn">
                {{ reviewCount }}
                <b-icon-star-fill variant="warning"></b-icon-star-fill>
            </b-button>
            <b-col cols="1">
                <h5 :class="{ today: isToday, sunday: isSunday }">
                    <b>{{ dayInfo.date.getDate() }}</b>
                </h5>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="text-white">
                <template v-if="clickable && !isSunday">
                    <AbsenceTile
                        v-if="$route.name === 'absence'"
                        :date="dayInfo.date"
                    ></AbsenceTile>
                    <Appoint
                        class=""
                        :variant="variant"
                        :date="dayInfo.date"
                        :title="dayInfo.title"
                        v-else
                    />
                </template>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Appoint from "./Appoint.vue";
import AbsenceTile from "../consultants/AbsenceTile.vue";

import {mapGetters, mapMutations} from "vuex";

export default {
    name: "Day",
    components: {
        Appoint,
        AbsenceTile
    },
    props: {
        dayInfo: {
            type: Object,
            required: true
        },
        clickable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            bgColor: ["bg-success", "bg-warning", "bg-disabled"]
        };
    },
    computed: {
        ...mapGetters(["isAuthenticated"]),
        isToday() {
            let date = this.dayInfo.date;
            let today = new Date();
            return (
                today.getDate() === date.getDate() &&
                today.getMonth() === date.getMonth() &&
                today.getFullYear() === date.getFullYear()
            );
        },
        isSunday() {
            return this.dayInfo.date.getDay() === 0;
        },
        isClickable() {
            return this.dayInfo.event === undefined;
        },
        variant() {
            if (this.dayInfo.variant !== undefined) {
                return this.dayInfo.variant;
            }
        },
        reviewCount() {

            return this.dayInfo.appointments ? this.dayInfo.appointments.filter(appointment => {
                return appointment.has_review;
            }).length : 0;
        }
    },
    methods: {
        ...mapMutations(["setSelectedDate"]),
        onClick() {
            this.setSelectedDate(this.dayInfo.date);
            this.$root.$emit("bv::toggle::modal", "appointment-modal");
        },
        onClickShowReviewsBtn() {
            this.setSelectedDate(this.dayInfo.date);
            this.$bvModal.show("show-reviews-modal");
        }
    }
};
</script>

<style lang="scss" scoped>
.day {
    min-height: 100px;
    // overflow: hidden;
    position: relative;
}

.today {
    color: #ffde03;
}

.sunday {
    color: #f00;
}

.review-count {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    padding: 5px;
    border-radius: 3px;
}
</style>
