import _ from "lodash";
import {ToastNotification} from "../exceptionHandler";
import {eventBus} from "../../eventBus";

const state = {
    appointments: [],
    selectedAppointment: {},
    calendarData: {},
};

export const getters = {
    getReviewsForSelectedDate:  (state, getters) => {
        const reviews = state.appointments ? state.appointments.filter(appointment => {
            if(getters.getSelectedDate)
            {
                return appointment.date === window.formatDate(getters.getSelectedDate) && appointment.has_review;
            }
            return false;
        }).map(appointment => {
            return {
                appointment_id: appointment.appointment_id,
                time: appointment.time,
                name: appointment.first_name + " " + appointment.last_name,
                date: appointment.date,
                scores: [...appointment.scores]
            };
        }) : [];
        return reviews;
    },
    getCalendarData: state => () => state.calendarData,
    getCalendarDataForDate: state => (date) => {
        return state.calendarData[formatDate(date)];
    },
    getAppointments: state => state.appointments,
    getAllAppointmentsOnDay: state => (date) => {
        return state.appointments.filter(el => el.date === formatDate(date));
    },
    getAppointmentsByConsultantId: (state, getters) => (consultantId) => {
        return state.appointments.filter(fAppointment => {
            return fAppointment.consultant_id === consultantId;
        });
    },
    getAppointmentsOnHour: (state, getters) => (date, hour) => {
        let dailyApp = state.appointments.filter(el => el.date === formatDate(date));
        return dailyApp.filter(el => el.time === hour);
    },
    getSelectedAppointment: (state) => state.selectedAppointment,
    getTimeslotsForDate: () => (date) => {
        let result = [
            {
                text: "09:00 - 10:30 Uhr",
                value: "09:00"
            },
            {
                text: "10:30 - 12:00 Uhr",
                value: "10:30"
            },
            {
                text: "12:00 - 13:30 Uhr",
                value: "12:00"
            }
        ];
        if (date.getDay() < 6) {
            result.push(
                {
                    text: "13:30 - 15:00 Uhr",
                    value: "13:30"
                },
                {
                    text: "15:00 - 16:30 Uhr",
                    value: "15:00"
                },
                {
                    text: "16:30 - 18:00 Uhr",
                    value: "16:30"
                }
            );
        }
        return result;
    }
};

const actions = {

    // fetchBlockedAppointmentsForVisitor({
    //     commit, getters
    // }, { vm }) {
    //     window.axios.get("/appointments?from=" + formatDate(getters.getFrom) + "&to=" + formatDate(getters.getTo) + "&fill=true")
    //         .then(res => {
    //             commit("setBlockedAppointments", res.data.data)
    //             commit("setCalendarData", res.data.calendar);
    //
    //         })
    //         .catch(err => ToastNotification.makeErrorToast(vm, err.response.data.message));
    // },

    fetchAppointmentsForUser({commit, getters, rootState, rootGetters}, {vm}) {
        let consultantFilter = "";
        if (Object.keys(getters.getSelectedConsultant).length > 0) {

            consultantFilter = "&consultant_id=" + getters.getSelectedConsultant.consultant_id;
        }

        return window.axios.get("/appointments?from=" + formatDate(getters.getFrom) + "&to=" + formatDate(getters.getTo) + consultantFilter)
            .then(res => {
                commit("setCalendarData", res.data.calendar);
                commit("setAppointments", res.data.data);
                if (rootGetters.isAuthenticated) {
                    ToastNotification.makeSuccessToast(vm, "Die Termine für den Zeitraum von " +
                        getters.getFrom.toLocaleDateString() + " bis zum " +
                        getters.getTo.toLocaleDateString() + " wurden erfolgreich geladen!");
                }
            })
            .catch(err => {

                ToastNotification.makeErrorToast(vm, err);
            });
    },

    createAppointment({dispatch, getters, commit}, {vm, payload}) {

        window.axios.post("/appointments", payload)
            .then(res => {
                dispatch("fetchAppointmentsForUser", {vm});
                ToastNotification.makeSuccessToast(vm, "Der Termin wurde erfolgreich eingetragen!")
                if (payload.images.length > 0) {
                    const formData = new FormData();

                    const images = [...payload.images];

                    for(let i = 0; i < images.length; i++)
                    {
                        let file = images[i];
                        formData.append("images[" + i + "]", file);
                    }

                    return window.axios.post("/appointments/" + res.data.data.appointment_id + "/images", formData,{
                        headers: {
                            "Content-Type":"multipart/form-data"
                        }
                    })
                        .then(uploadRes => {
                            ToastNotification.makeSuccessToast(vm, "Upload ihrer Dateien erfolgreich!");
                        });
                }
            })
            .catch(err => {
                ToastNotification.makeErrorToast(vm, err);
            });

    },

    updateAppointment({commit, getters}, {vm, cb}) {
        window.axios.put("/appointments/" + getters.getSelectedAppointment.appointment_id, getters.getSelectedAppointment)
            .then(res => {
                commit("updateAppointment", res.data.data);
                if (_.isFunction(cb)) {
                    cb(res);
                }
                ToastNotification.makeSuccessToast(vm, "Der Termin wurde erfolgreich überarbeitet!");
            })
            .catch(err => ToastNotification.makeErrorToast(vm, err))
    },
    deleteAppointment({commit, getters}, {vm}) {
        window.axios.delete("/appointments/" + getters.getSelectedAppointment.appointment_id)
            .then(() => {
                commit("deleteAppointment", getters.getSelectedAppointment.appointment_id);
                ToastNotification.makeSuccessToast(vm, "Termin wurde erfolgreich gelöscht!")

            })
            .catch(err => ToastNotification.makeErrorToast(vm, err))
    },
    deleteAppointmentImage({commit, getters}, {vm, filenames, appointmentId,callback})
    {
        window.axios.delete("/appointments/" + appointmentId + "/images?files=" + filenames.join(","))
        .then((res)=>{
            if(_.isFunction(callback))
            {
                callback(res);
            }
        })
        .catch(error => {
            if(_.isFunction(callback))
            {
                callback(error);
            }
        });
    }
};

const mutations = {
    setCalendarData: (state, calendarData) => state.calendarData = calendarData,
    setBlockedAppointments: (state, blockedAppointments) => state.blockedAppointments = blockedAppointments,
    setAppointments: (state, appointments) => state.appointments = appointments,
    addAppointment: (state, appointment) => state.appointments.push(appointment),
    updateAppointment: (state, appointment) => {
        const index = state.appointments.findIndex(el => el.appointment_id === appointment.appointment_id);
        if (index !== -1) {
            state.appointments.splice(index, 1, appointment);
        }
    },
    setSelectedAppointment: (state, appointment) => state.selectedAppointment = appointment,
    deleteAppointment: (state, appointment_id) => state.appointments = state.appointments.filter(el => el.appointment_id !== appointment_id)

};

export default {
    state,
    getters,
    actions,
    mutations
}
