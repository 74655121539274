<template>
  <b-container>
    <b-row>
      <b-col offset="2" md="8" align-self="center">
        <b-card title="Profil bearbeiten" class="login-card">
          <b-form @submit.prevent="changeProfile">
            <b-form-group label="Name" label-for="name-fields">
              <b-input-group class="mb-2" id="name-fields">
                <b-form-input
                  type="text"
                  v-model="first_name"
                  aria-label="Vorname"
                  placeholder="Vorname"
                ></b-form-input>
                <b-form-input
                  type="text"
                  v-model="last_name"
                  aria-label="Nachname"
                  placeholder="Nachname"
                ></b-form-input>
              </b-input-group>
            </b-form-group>

            <b-form-group label="E-Mail" label-for="email-field">
              <b-input
                type="email"
                placeholder="E-Mail"
                id="email-field"
                v-model="email"
              ></b-input>
            </b-form-group>

            <b-form-group label="Geburtsdatum" label-for="birtday-field">
              <b-form-input
                type="date"
                required
                v-model="birthday"
                id="birthday"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Passwort" label-for="password-field">
              <b-input
                type="password"
                placeholder="Passwort"
                id="password-field"
                v-model="password"
              ></b-input>
            </b-form-group>
            <b-button type="submit" variant="success">Speichern</b-button>
          </b-form>
        </b-card></b-col
      >
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      password: "",
    };
  },
  computed: {
    ...mapGetters(["getLoggedInUser", "getSelectedUser"]),
    first_name: {
      get() {
        return this.getSelectedUser.first_name;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.first_name = value;
        this.setSelectedUser(u);
      },
    },
    last_name: {
      get() {
        return this.getSelectedUser.last_name;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.last_name = value;
        this.setSelectedUser(u);
      },
    },
    email: {
      get() {
        return this.getSelectedUser.email;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.email = value;
        this.setSelectedUser(u);
      },
    },
    birthday: {
      get() {
        return this.getSelectedUser.birthday;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.birthday = value;
        this.setSelectedUser(u);
      },
    },
  },
  methods: {
    ...mapActions(["updateUser"]),
    ...mapMutations(["setSelectedUser", "setLoggedInUser"]),
    changeProfile() {
      const user = this.getSelectedUser;
      if (this.password !== "") {
        user.password = this.password;
      }
      this.setSelectedUser(Object.assign({}, user));
      this.updateUser({vm: this});
      this.setLoggedInUser(user);
    },
  },
  mounted() {
    if (this.getSelectedUser.user_id !== this.getLoggedInUser.user_id) {
      this.setSelectedUser(Object.assign({}, this.getLoggedInUser));
    }
  },
};
</script>

<style lang="scss" scoped>
input,
textarea {
    border: 1px #cecece;
    background: #f1f1f1;
    box-shadow: none;

    &#visitor-field {
        background: #fff;
    }
}


</style>
