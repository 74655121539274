<template>
    <b-modal id="timeslot_cfg_modal" size="sm"
             :title="getSelectedConsultant.first_name + ' ' + getSelectedConsultant.last_name">
            <b-form-checkbox switch size="sm" :checked="isAbsentAllDay" v-model="allDay">
                <strong>Ganztägig</strong>
            </b-form-checkbox>
            <hr>
            <div
                class="timeslot-cfg"
                v-for="timeslot in timeslots"
                :key="timeslot.value"
            >
                <b-form-checkbox
                    switch
                    :checked="isAbsentForTimeslotValue(timeslot.value)"
                    size="sm"
                    @change="switchAbsence(timeslot.value)"
                >
                    {{ timeslot.text }}
                </b-form-checkbox>
            </div>

        <template #modal-footer="{ok,cancel,hide}">
            <b-button variant="danger" size="sm" @click="cancel()">Abbrechen</b-button>
            <b-button variant="success" size="sm" @click="updateAbsence" :disable="loading"><b-spinner small v-if="loading"></b-spinner> Speichern</b-button>
        </template>
    </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {eventBus} from "../../eventBus";

export default {
    name: "TimeslotAbsenceModal",
    data() {
        return {
            timeslotData: [],
            loading: false,
            allDay: true,
        }
    },
    watch:{
      allDay:function(newValue, oldValue )
      {
          if(newValue)
          {
              this.timeslotData = [];
          }
      }
    },
    computed: {
        ...mapGetters([
            "getSelectedConsultant",
            "getTimeslotsForDate",
            "getSelectedDate"
        ]),
        isAbsentAllDay() {
            return this.allDay && !this.hasTimeslots;
        },
        timeslots() {
            return this.getTimeslotsForDate(this.getSelectedDate);
        },
        hasAbsenceForChosenDate() {
            return this.getAbsenceForChosenDate.length > 0;
        },
        hasTimeslots() {
            const elementWithTimeslots = this.getAbsenceForChosenDate.filter(absence => {
                return absence.timeslots !== null
            })
            return elementWithTimeslots.length > 0;
        },
        getAbsenceForChosenDate() {
            if (this.getSelectedConsultant.absence !== undefined) {
                return this.getSelectedConsultant.absence.filter(absence => {
                    return absence.date === formatDate(this.getSelectedDate);
                })
            }
            return [];
        },
        getSelectedConsultantsTimeslots() {
            return this.hasAbsenceForChosenDate
                ? this.getAbsenceForChosenDate[0].timeslots === null
                    ? []
                    : this.getAbsenceForChosenDate[0].timeslots
                : [];
        },
    },
    methods: {
        ...mapActions([
            "addAbsenceDayToConsultant",
            "updateAbsenceDay"
        ]),
        isAbsentForTimeslotValue(timeslotValue) {
            return this.timeslotData.includes(timeslotValue);
        },
        onSetSelectedConsultant(consultant)
        {
            // setting some defaults
            this.allDay = true;
            this.timeslotData = [];

            const consultantsSlots = this.getSelectedConsultantsTimeslots;
            consultantsSlots.forEach(slot => {
               if(!this.timeslotData.includes(slot))
               {
                   this.timeslotData.push(slot);
               }
            });
            this.allDay = this.hasAbsenceForChosenDate && this.isAbsentAllDay;
        },
        getAbsenceId()
        {
            return this.hasAbsenceForChosenDate ? this.getAbsenceForChosenDate[0].absence_day_id : null;
        },
        switchAbsence(timeslotValue) {
            const idx = this.timeslotData.indexOf(timeslotValue);
            if (idx > -1) {
                this.timeslotData.splice(idx, 1);
                return;
            }
            this.allDay = false;

            this.timeslotData.push(timeslotValue);
        },
        updateAbsence() {
            if (!this.allDay && this.timeslotData.length < 1) {
                return;
            }
            this.loading = true;
            const consultantId = this.getSelectedConsultant.consultant_id;
            const day = {
                date: window.formatDate(this.getSelectedDate),
                timeslots: this.timeslotData.length > 0 ? this.timeslotData : null
            };
            if (this.hasAbsenceForChosenDate) {
                this.updateAbsenceDay({
                    vm: this,
                    consultantId: consultantId,
                    absenceId: this.getAbsenceForChosenDate[0].absence_day_id,
                    day,
                    s_cb: this.disableLoading.bind(this),
                    f_cb: this.disableLoading.bind(this)
                });
                return;
            }
            this.addAbsenceDayToConsultant({
                vm: this,
                id: consultantId,
                day,
                s_cb: this.disableLoading.bind(this),
                f_cb: this.disableLoading.bind(this)
            });
        },
        resetTimeslots() {
            this.allDay = this.hasTimeslots;
            this.timeslotData = [];
        },
        disableLoading() {
            this.loading = false;
            this.$bvModal.hide("timeslot_cfg_modal");
        },
    },
    created()
    {
        eventBus.$on("STORE_SET_SELECTED_CONSULTANT", this.onSetSelectedConsultant);
    },
    beforeDestroy()
    {
        eventBus.$off("STORE_SET_SELECTED_CONSULTANT", this.onSetSelectedConsultant);
    }
}
</script>

<style lang="scss">
.modal-footer {
    flex-direction: row;
    justify-content: space-between;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
