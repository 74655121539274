<template>
  <footer>
    <b-container fluid class="contact grey">
      <b-container>
        <b-row class="contact">
          <b-col md="6" class="text-center text-md-left mb-3 mb-md-0">
            <h5>Ruhe & Co. Handelsges. mbH</h5>
            <address>
              Hannoversche Str.&nbsp;55<br />
              37075 Göttingen<br />
              Tel.: 0551&nbsp;‑&nbsp;38908‑0<br />
              Fax: 0551&nbsp;‑&nbsp;38908‑61
            </address>
          </b-col>
          <b-col md="6" class="text-center text-md-right">
            <p>
              <a
                href="https://www.ruhe.de/impressum"
                target="_blank"
                rel="noopener noreferrer nofollow"
                >Impressum</a
              >
            </p>
            <p>
              <a
                href="https://www.ruhe.de/datenschutz"
                target="_blank"
                rel="noopener noreferrer nofollow"
                >Datenschutz</a
              >
            </p>
            <p>
              <a
                href="https://www.ruhe.de/kontakt"
                target="_blank"
                rel="noopener noreferrer nofollow"
                >Kontakt</a
              >
            </p>
            <p><router-link to="/login">Login</router-link></p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <b-container fluid class="copyright bg-dark text-white">
      <b-container>
        <b-row>
          <b-col lg="6" md="12" class="text-center text-lg-left mb-1 mb-lg-0"
            >&copy; Ruhe &amp; Co. GmbH – Fachgroßhandel für Bodenbeläge,
            Parkett und Tapeten</b-col
          >
          <b-col lg="6" md="12" class="text-center text-lg-right"
            >Konzept und Realisierung:
            <a
              href="https://werbeagentur-gottschalg.de"
              target="_blank"
              rel="nofollow"
              >Werbeagentur&nbsp;Gottschalg
              <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right
            ></a>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {},
  methods: {}
};
</script>

<style scoped lang="scss">
.copyright {
  font-size: 0.875em;
  padding: 1em 0;
}

.grey {
  background-color: #e5e5e5;
}
footer {
  address {
    margin-bottom: 0;
  }
  a {
    color: #ffffff;
  }
}
.contact {
  color: #575757;
  padding: 0.5em 0;

  a {
    color: #575757;
  }
  p {
    margin-bottom: 0.4em;
  }
}

@media print {
  footer {
    display: none;
  }
}
</style>
