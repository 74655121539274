<template>
  <b-container class="content py-3">
    <b-row class="mt-3">
      <b-col class="text-center mt-3">
        <h2>Terminansicht</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="m-3">
        Ihre angegebenen persönlichen Daten können Sie hier bearbeiten oder den
        gewünschten Termin komplett stornieren.
      </b-col>
    </b-row>
    <div v-if="isAppointFilled" class="m-3">
      <b-row>
        <b-col md="12"><h5>Kontaktdaten</h5></b-col>
      </b-row>
      <b-row>
        <b-col md="6"> Name: </b-col>
        <b-col md="6">{{ fullName }}</b-col>
      </b-row>
      <b-row>
        <b-col md="6"> E-Mail: </b-col>
        <b-col md="6">
          {{ appointment.email }}
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6"> Telefon: </b-col>
        <b-col md="6"> {{ appointment.phone }} </b-col>
      </b-row>
      <b-row>
        <b-col md="6"> Adresse: </b-col>
        <b-col md="6" v-html="address"> </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col md="12" class="mt-1"><h5>Beratung</h5></b-col>
      </b-row>
      <b-row>
        <b-col md="6"> Termin: </b-col>
        <b-col md="6">
          {{
            new Date(appointment.date).toLocaleDateString("de-DE", {
              weekday: "long",
              day: "numeric",
              month: "long",
              year: "numeric",
            }) +
            " " +
            appointment.time +
            " Uhr"
          }}
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6"> Teilnehmer: </b-col>
        <b-col md="6"> {{ appointment.visitor_count }} </b-col>
      </b-row>
      <b-row>
        <b-col md="6"> Beratungswunsch: </b-col>
        <b-col md="6" class="mb-4">
          {{ appointment.message }}
        </b-col>
      </b-row>
      <b-row class="mt-3 justify-content-center">
        <b-col md="2">
          <b-button variant="primary" @click="cancelAppointment">
            Stornieren
          </b-button>
        </b-col>
        <b-col md="2">
          <b-button variant="info" @click="show = true">Bearbeiten</b-button>
        </b-col>
      </b-row>
    </div>
    <b-modal
      id="userdata-modal"
      scrollable
      size="lg"
      :title="'Persönliche Angaben ändern'"
      hide-footer
      v-model="show"
    >
      <b-form @submit.prevent.stop="updateData">
        <b-form-group label="Anrede:">
          <b-form-radio-group
            :options="form.salutation.options"
            v-model="form.salutation.value"
          >
          </b-form-radio-group>
        </b-form-group>

        <b-form-group label="Titel:" label-for="title-field">
          <b-input
            type="text"
            placeholder="Titel"
            id="title-field"
            v-model="form.title.value"
            list="titlelist"
          ></b-input>
          <datalist id="titlelist">
            <option value="Dr."></option>
            <option value="Prof."></option>
            <option value="Prof. Dr."></option>
          </datalist>
        </b-form-group>
        <b-form-group label="Name:" label-for="name-fields" class="required">
          <b-row>
            <b-col>
              <b-form-input
                @change="setDirty('first_name')"
                type="text"
                v-model="form.first_name.value"
                aria-label="Vorname"
                placeholder="Vorname"
                required
              ></b-form-input>
              <b-form-invalid-feedback :state="validate('first_name')">
                Der Vorname muss länger als 3 Zeichen sein.
              </b-form-invalid-feedback>
            </b-col>
            <b-col>
              <b-form-input
                @change="setDirty('last_name')"
                type="text"
                v-model="form.last_name.value"
                aria-label="Nachname"
                placeholder="Nachname"
                required
              ></b-form-input>
              <b-form-invalid-feedback :state="validate('last_name')">
                Der Nachname muss länger als 3 Zeichen sein.
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row>
          <b-col>
            <b-form-group
              label="E-Mail:"
              label-for="mail-field"
              class="required"
              invalid-feedback="Bitte geben sie eine gültige E-Mail-Adresse ein!"
              :state="validate('email')"
            >
              <b-input
                type="email"
                id="mail-field"
                placeholder="Email"
                v-model="form.email.value"
                required
                @change="setDirty('email')"
              ></b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Telefonnummer:"
              label-for="phone-field"
              class="required"
              invalid-feedback="Bitte geben sie eine Telefonnummer ein."
              :state="validate('phone')"
            >
              <b-input
                type="tel"
                id="phone-field"
                placeholder="Telefon"
                v-model="form.phone.value"
                required
                @change="setDirty('phone')"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Adresse" label-size="lg" class="b">
          <div class="bordered">
            <b-form-group label="Straße und Hausnummer:" label-for="street">
              <b-form-input
                type="text"
                aria-label="Street"
                id="street"
                v-model="form.street.value"
                placeholder="Straße, Hausnummer"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Postleitzahl und Ort:"
              label-for="address-fields"
            >
              <b-row>
                <b-col>
                  <b-form-input
                    type="text"
                    v-model="form.postcode.value"
                    aria-label="Postleitzahl"
                    placeholder="Postleitzahl"
                  ></b-form-input>
                </b-col>
                <b-col>
                  <b-form-input
                    type="text"
                    v-model="form.city.value"
                    aria-label="Ort"
                    placeholder="Ort"
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-form-group>
          </div>
        </b-form-group>
        <b-form-group
          label-cols-md="12"
          :label="'Anzahl der Besuchenden: ' + form.visitor_count.value"
          label-for="visitor-field"
        >
          <div class="range-slider--wrapper">
            <span>{{ form.visitor_count.min }}</span>
            <b-input
              type="range"
              id="visitor-field"
              :min="form.visitor_count.min"
              :max="form.visitor_count.max"
              required
              v-model="form.visitor_count.value"
            ></b-input>
            <span>{{ form.visitor_count.max }}</span>
          </div>
        </b-form-group>
        <b-form-group
          label="Ihr Beratungswunsch:"
          label-for="message-area"
          message="Beschreibung der gewünschten Beratung"
          class="required"
          invalid-feedback="Bitte geben Sie eine Nachricht mit mindestens 8 Zeichen ein!"
          :state="validate('message')"
        >
          <b-form-textarea
            placeholder="Ich interessiere mich besonders für ..."
            rows="4"
            id="message-area"
            v-model="form.message.value"
            required
            @change="setDirty('message')"
          >
          </b-form-textarea>
        </b-form-group>
        <b-row class="mt-2">
          <b-col md="3">
            <b-button @click="show = false" size="md" variant="primary">
              Abbrechen
            </b-button>
          </b-col>
          <b-col>
            <b-button type="submit" size="md" variant="success">
              Senden
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { ToastNotification } from "../store/exceptionHandler";

export default {
  name: "AppointmentVisitorView",
  data() {
    return {
      appointment: {},
      show: false,
      form: {
        salutation: {
          dirty: false,
          options: [
            {
              text: "keine Anrede",
              value: "d"
            },
            {
              text: "Frau",
              value: "w"
            },
            {
              text: "Herr",
              value: "m"
            }
          ],
          value: "d"
        },
        appointmentTime: {
          dirty: false,
          value: ""
        },
        title: {
          dirty: false,
          value: ""
        },
        first_name: {
          dirty: false,
          value: "",
          validate() {
            return this.dirty ? this.value.length > 3 : null;
          }
        },
        last_name: {
          dirty: false,
          value: "",
          validate() {
            return this.dirty ? this.value.length > 3 : null;
          }
        },
        email: {
          dirty: false,
          value: "",
          validate() {
            return this.dirty ? /^.+@.+\..+$/.test(this.value) : null;
          }
        },
        phone: {
          dirty: false,
          value: "",
          validate() {
            return this.dirty ? this.value.length > 0 : null;
          }
        },
        street: {
          dirty: false,
          value: ""
        },
        postcode: {
          dirty: false,
          value: ""
        },
        city: {
          dirty: false,
          value: ""
        },
        visitor_count: {
          dirty: false,
          value: 1,
          min: 1,
          max: 6
        },
        message: {
          dirty: false,
          value: "",
          validate() {
            return this.dirty ? this.value.length > 7 : null;
          }
        },
        privacy: {
          dirty: false,
          value: false,
          validate() {
            return this.dirty ? this.value : null;
          }
        }
      }
    };
  },
  props: {
    appointmentId: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  computed: {
    fullName() {
      let salutation = "";
      switch (this.appointment.gender) {
        case "m":
          salutation = "Herr ";
          break;
        case "w":
          salutation = "Frau ";
          break;
        case "d":
          break;
      }
      let title = this.appointment.title === null ? "" : this.appointment.title;
      return (
        salutation +
        " " +
        title +
        " " +
        this.appointment.first_name +
        " " +
        this.appointment.last_name
      );
    },
    isAppointFilled() {
      return Object.keys(this.appointment).length > 0;
    },
    address() {
      if (
        this.appointment.zipcode === null ||
        this.appointment.city === null ||
        this.appointment.street === null
      )
        return "Keine Adresse angegeben!";
      return (
        this.appointment.street +
        "<br>" +
        this.appointment.zipcode +
        " " +
        this.appointment.city
      );
    }
  },
  methods: {
    ...mapMutations(["setSelectedAppointment"]),
    ...mapActions(["deleteAppointment"]),
    setDirty(fieldName) {
      this.form[fieldName].dirty = true;
    },
    validate(fieldName) {
      return this.form[fieldName].validate();
    },
    fetchAppointment() {
      axios
        .get("/appointments/" + this.appointmentId + "?tmp_token=" + this.token)
        .then(res => {
          this.appointment = res.data.data;
          this.setFormData();
        })
        .catch(err => {
          if (err.response) {
            if (err.response.status > 400) {
              this.$router.push({ name: "calendar" });
            }
          }
        });
    },
    cancelAppointment() {
      if (confirm("Möchten Sie Ihren Termin wirklich stornieren?")) {
        axios
          .delete(
            "/appointments/" + this.appointmentId + "?tmp_token=" + this.token
          )
          .then(() => {
            ToastNotification.makeSuccessToast(
              this,
              "Der Termin wurde erfolgreich storniert"
            );
            this.$router.push({ name: "calendar" });
          })
          .catch(err => ToastNotification.makeErrorToast(this, err.message));
      }
    },
    updateData() {
      let appointObject = {
        time: this.appointment.time,
        date: this.appointment.date,
        gender: this.form.salutation.value,
        city: this.form.city.value,
        title: this.form.title.value,
        first_name: this.form.first_name.value,
        last_name: this.form.last_name.value,
        email: this.form.email.value,
        phone: this.form.phone.value,
        street: this.form.street.value,
        zipcode: this.form.postcode.value,
        visitor_count: this.form.visitor_count.value,
        message: this.form.message.value
      };
      axios
        .put(
          "/appointments/" + this.appointmentId + "?tmp_token=" + this.token,
          appointObject
        )
        .then(res => {
          this.appointment = res.data.data;
          ToastNotification.makeSuccessToast(
            this,
            "Die Daten wurden erfolgreich bearbeitet!"
          );
          this.show = false;
        })
        .catch(err => ToastNotification.makeErrorToast(this, err.message));
    },
    setFormData() {
      this.form.appointmentTime.value = this.appointment.time;
      this.form.salutation.value = this.appointment.gender;
      this.form.city.value = this.appointment.city;
      this.form.title.value = this.appointment.title;
      this.form.first_name.value = this.appointment.first_name;
      this.form.last_name.value = this.appointment.last_name;
      this.form.email.value = this.appointment.email;
      this.form.phone.value = this.appointment.phone;
      this.form.street.value = this.appointment.street;
      this.form.postcode.value = this.appointment.zipcode;
      this.form.visitor_count.value = this.appointment.visitor_count;
      this.form.message.value = this.appointment.message;
    }
  },
  created() {
    this.fetchAppointment();
  }
};
</script>

<style>
</style>
