<template>
    <CollapsableCard v-if="appointments.length > 0" :key="timeslot.value" class="mt-3" :title="title"
                     :id="timeslot.value" :initial-visibility="collapsed" tag="upper">
        <template v-slot:title-icon>
            <b-icon :icon="titleIcon.icon" :variant="titleIcon.variant"></b-icon>
        </template>
        <vue-gallery-slideshow :images="currentImages" :index="index" @close="index = null" ref="img-gallery">
            <button class="rotate-cw btn btn-secondary btn-sm" @click.stop="rotateImg(90)"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></button>
            <button class="rotate-ccw btn btn-secondary btn-sm" @click.stop="rotateImg(-90)"><b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise></button>
        </vue-gallery-slideshow>

        <CollapsableCard v-for="appointment in appointments" v-if="appointments" :key="appointment.appointment_id"
                         class="mt-3" :id="appointment.appointment_id" :title="appointmentTitle(appointment)">
            <template v-slot:title-icon>
                <b-icon :icon="appointmentIcon(appointment).icon"
                        :variant="appointmentIcon(appointment).variant"></b-icon>
            </template>
            <template v-slot:post-title>
                <b-btn-group @click.stop="">
                    <b-button class="btn btn-info btn-sm" v-b-tooltip.hover title="Notiz erstellen/bearbeiten"
                              v-b-modal="'note-modal-' + appointment.appointment_id"
                              @click="appointmentNote = appointment.note">
                        <b-icon-pen></b-icon-pen>
                    </b-button>
                    <b-button class="btn btn-success btn-sm" @click.stop="()=> confirmAppointment(appointment)"
                            v-if="!appointment.user_confirmed">Bestätigen
                    </b-button>
                    <b-button class="btn btn-secondary btn-sm" @click="setCurrentImages(appointment)" v-b-tooltip.hover title="Kundenfotos ansehen" v-if="appointment.images.length > 0">
                        <b-icon-card-image></b-icon-card-image>
                    </b-button>
<!--                    <b-dropdown v-if="appointment.images.length > 0" size="sm" dropup>-->
<!--                        <template #button-content>-->
<!--                            <b-icon-card-image></b-icon-card-image>-->
<!--                        </template>-->
<!--                        <b-dropdown-item @click="setCurrentImages(appointment)"><b-icon-folder2-open></b-icon-folder2-open> Bilder ansehen</b-dropdown-item>-->
<!--                        &lt;!&ndash; <b-dropdown-item variant="danger" @click="deleteImage(appointment)"><b-icon-trash variant="danger"></b-icon-trash> Delete <b-spinner v-if="showDeleteImgSpinner" small></b-spinner></b-dropdown-item> &ndash;&gt;-->
<!--                    </b-dropdown>-->
                    <b-button size="sm" v-b-tooltip.hover title="Bewertung durch den Kunden anfordern" v-if="!appointment.review_sent" @click="() => onClickSendReviewRequestBtn(appointment)">
                        <b-icon-envelope-fill></b-icon-envelope-fill>
                        <b-icon-star-fill variant="warning"></b-icon-star-fill>
                    </b-button>
                </b-btn-group>
                <span class="text-secondary post-title">Wurde {{ appointment.submitted }} erstellt.</span>
            </template>
            <AppointListItem :appoint="appointment"/>
            <b-modal :id="'note-modal-' + appointment.appointment_id" title="Notiz bearbeiten"
                     @ok="() => saveNote(appointment)" @cancel="appointmentNote = ''">
                <textarea rows="6" v-model="appointmentNote" class="form-control">

                </textarea>
            </b-modal>
        </CollapsableCard>
    </CollapsableCard>
</template>

<script>
import AppointListItem from "../appointments/AppointListItem";
import CollapsableCard from "./CollapsableCard";
import {mapActions, mapMutations} from "vuex";
import VueGallerySlideshow from "vue-gallery-slideshow";

export default {
    name: "TimeslotContainer",
    components: {CollapsableCard, AppointListItem, VueGallerySlideshow},
    props: [
        "appointments",
        "timeslot",
        "collapsed"
    ],
    data() {
        return {
            appointmentNote: "",
            index: null,
            currentImages: [],
            imgSelector:".vgs__container__img",
            imgRotation:0,
            showDeleteImgSpinner:false
        }
    },
    computed: {
        title() {
            let result = this.timeslot.value;

            result += this.appointmentsWithoutConfirmation > 0
                ? " - Unbestätigt: " + (this.appointmentsWithoutConfirmation + "/" + this.appointments.length)
                : "";

            result += this.appointmentsWithoutConsultant > 0
                ? " - Unbearbeitet: " + this.appointmentsWithoutConsultant + "/" + this.appointments.length
                : " - Bearbeitet: " + (this.appointments.length - this.appointmentsWithoutConsultant) + "/" + this.appointments.length;

            return result;
        },
        appointmentsWithoutConsultant() {
            return this.appointments.filter(appointment => {
                return appointment.consultant === null;
            }).length
        },
        appointmentsWithoutConfirmation() {
            return this.appointments.filter(appointment => {
                return !appointment.user_confirmed;
            }).length;
        },

        titleIcon() {
            return this.appointmentsWithoutConsultant > 0 || this.appointmentsWithoutConfirmation > 0 ? {
                icon: "exclamation-circle-fill",
                variant: "warning"
            } : {icon: "check-circle-fill", variant: "success"};
        },
        imgRotationValue()
        {
            return this.imgRotation + "deg";
        }
    },
    methods: {
        ...mapActions(["updateAppointment","deleteAppointmentImage", "sendReviewRequest"]),
        ...mapMutations(["setSelectedAppointment"]),
        deleteImage(appointment){
            if(window.confirm("Wollen sie diese Medien wirklich endgültig löschen?"))
            {
                this.showDeleteImgSpinner = true;
                const images = appointment.images.map(image => {
                    return image.filename;
                });
                this.deleteAppointmentImage({vm:this,appointmentId:appointment.appointmentId, filenames:images})
            }
        },
        setCurrentImages(appointment) {
            this.currentImages = appointment.images.map(image => {
                return image.url;
            });
            if (this.currentImages.length > 0) {
                this.index = 0;
            } else {
                this.index = null;
            }
        },
        onClickSendReviewRequestBtn(appointment)
        {
            const payload = {appointmentId:appointment.appointment_id, vm:this};

            this.sendReviewRequest(payload);
        },
        saveNote(appointment) {
            appointment.note = this.appointmentNote;
            this.setSelectedAppointment(Object.assign({}, appointment));
            this.updateAppointment({vm: this});
            this.appointmentNote = "";
        },
        confirmAppointment(appointment) {
            appointment.user_confirmed = true;
            this.setSelectedAppointment(Object.assign({}, appointment));
            this.updateAppointment({vm: this});
        },
        appointmentTitle(appointment) {
            let result = "Name: " + appointment.first_name[0] + ". " + appointment.last_name;

            result += (appointment.consultant === null)
                ? " - Unbearbeitet"
                : "";
            result += (appointment.user_confirmed)
                ? ""
                : " - Unbestätigt"

            return result;
        },
        appointmentIcon(appointment) {
            if (!appointment.user_confirmed) {
                return {icon: "exclamation-circle-fill", variant: "danger"};
            } else if (appointment.consultant === null) {
                return {icon: "exclamation-circle-fill", variant: "warning"};
            }
            return {icon: "check-circle-fill", variant: "success"};
        },
        rotateImg(amount)
        {
            this.imgRotation += amount;
            const el = document.querySelector(this.imgSelector);
            el.style.transform = "rotate("+this.imgRotationValue+")";
        },
        setDeleteImageSpinner(value)
        {
            this.showDeleteImgSpinner = value;
        }
    }
}
</script>

<style lang="scss">
.vgs{
    .rotate-cw, .rotate-ccw{
        position:absolute;
        top: 20px;
    }
    .rotate-cw{
        right:20px;
    }
    .rotate-ccw{
        left:20px;
    }
}
</style>
