const state = {
    criteria:[]
};

const getters = {
    getCriteria: state => state.criteria,
};

const mutations = {
    setCriteria: (state, criteria) => state.criteria = criteria,
}

const actions = {
    fetchCriteria: ({commit}) => {
        window.axios.get("/criteria")
            .then(response => {
                commit("setCriteria", response.data.data);
            })
            .catch(error => {
               console.log(error);
            });
    },
    createCriteria:({commit, dispatch},payload )=> {
        window.axios.post("/criteria", payload)
            .then(response =>  {
                dispatch("fetchCriteria");
            })
            .catch(error => {
               console.log(error);
            });
    },
    updateCriteria: ({commit, dispatch}, payload) => {
        window.axios.put("/criteria/" + payload.id, payload)
            .then(response => {
                dispatch("fetchCriteria");
            })
            .catch(error => {
                console.log(error);
            })
    },
    submitScores:({commit,dispatch}, payload) => {
        return window.axios.post("/appointments/" + payload.appointmentId + "/scores", {
            scores: payload.formData,
            token: payload.token
        }
        ,)
            .then( res => {
            })
            .catch(err => {
                console.log(err);
            })
    },
    sendReviewRequest: ({commit, dispatch}, payload) => {
        window.axios.post("/appointments/" + payload.appointmentId + "/review")
            .then(res => {
                dispatch("fetchAppointmentsForUser", payload.vm)
            })
            .catch(err => {
               console.log(err);
            });
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
