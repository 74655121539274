<template>
  <b-table
    striped
    :fields="fields"
    :items="getAppointments"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
  >
    <template #cell(name)="data">
      {{ data.item.last_name + ", " + data.item.first_name }}
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AppointmentTable",
  data() {
    return {
      sortBy: "date",
      sortDesc: false,
      fields: [
        {
          key: "date",
          label: "Datum",
          sortable: true,
          formatter: (value, key, item) => {
              const date = new Date(value)
            return ('0' + date.getDate()).slice(-2) + "." + ('0' + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
          },
            sortByFormatted:true
        },
        {
          key: "time",
          label: "Uhrzeit",
          sortable: true
        },
        {
          key: "name",
          label: "Besucher",
          sortable: false
        },
        {
          key: "message",
          label: "Beratungswunsch",
          sortable: false
        },
        {
          key: "consultant",
          label: "Berater",
          sortable: true,
          formatter: (value, key, item) => {
            if (value === null) {
              return "-";
            }
            return value.last_name + ", " + value.first_name;
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getAppointments"])
  },
  methods: {}
};
</script>

<style>
</style>
