<template>
  <b-modal
    id="appointment-list-modal"
    right
    scrollable
    size="xl"
    cancel-title="Abbrechen"
    :title="'Terminübersicht für den ' + dateString"
  >
      <template #modal-footer="{close,ok}">
          <b-button variant="danger" @click="close()">Abbrechen</b-button>

          <b-button variant="success" @click="ok()">Ok</b-button>
      </template>
    <b-container>
      <b-row>
        <b-col cols="auto" class="mr-auto">
          <b-button
            variant="info"
            v-b-popover.hover="'Neuen Termin anlegen'"
            :disabled="getAvailableConsultantsForDate(getSelectedDate).length < 1"
            @click="$root.$emit('bv::toggle::modal', 'appointment-modal')"
            size="md"
          >
            <b-icon-pencil-fill />
            Neuer Termin
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            @click="emitEvent()"
            v-b-popover.hover="'Termine auf bzw. zu klappen'"
            size="md"
          >
            Termin-Details
          </b-button>
        </b-col>
      </b-row>
      <TimeslotContainer
        v-for="timeslot in timeslots"
        :key="timeslot.value"
        :appointments="filterAppointmentsByTime(timeslot.value)"
        :timeslot="timeslot"
        :collapsed="collapsed"
      />
    </b-container>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import CollapsableCard from "../general/CollapsableCard.vue";
import AppointListItem from "./AppointListItem.vue";
import TimeslotContainer from "../general/TimeslotContainer";
import { eventBus } from "../../eventBus";

export default {
  name: "AppointmentListModal",
  components: {
    TimeslotContainer,
    CollapsableCard,
    AppointListItem
  },
  data() {
    return {
      collapsed: false
    };
  },
  props: {},
  computed: {
    ...mapGetters([
      "getAllAppointmentsOnDay",
      "getSelectedDate",
      "getAppointmentsOnHour",
      "getAvailableConsultantsForDate",
      "getTimeslotsForDate"
    ]),
    dateString() {
      return this.getSelectedDate
        ? ("0" + this.getSelectedDate.getDate()).slice(-2) +
            "." +
            ("0" + (this.getSelectedDate.getMonth() + 1)).slice(-2) +
            "." +
            this.getSelectedDate.getFullYear()
        : "";
    },
    timeslots() {
      return this.getTimeslotsForDate(this.getSelectedDate);
    }
  },
  methods: {
    emitEvent() {
      eventBus.$emit("TOGGLE_COLLAPSABLE_BUTTON_CLICKED", "upper");
    },
    filterAppointmentsByTime(time) {
      return this.getAppointmentsOnHour(this.getSelectedDate, time);
    }
  }
};
</script>

<style lang='scss'>
.modal-footer{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}
</style>
