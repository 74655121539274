import { ToastNotification } from "../exceptionHandler";

const state = {
    userList: [],
    selectedUser: {}
};

const getters = {
    getSelectedUser: state => state.selectedUser,
    getUserList: state => state.userList
};

const actions = {
    fetchUserList({ commit }, { vm }) {
        window.axios.get("/users")
            .then(res => commit("setUserList", res.data.data))
            .catch(err => ToastNotification.makeErrorToast(vm, err))
            .finally()
    },
    createUser({ commit, state }, { vm }) {
        window.axios.post("/users", state.selectedUser)
            .then(res => commit("addUser", res.data.data))
            .catch(err => ToastNotification.makeErrorToast(vm, err))
    },
    updateUser({ commit, state }, { vm }) {
        window.axios.put("/users/" + state.selectedUser.user_id, state.selectedUser)
            .then(res => {
                commit("updateUser", res.data.data);
                let message = "Die Benutzerdaten von " + res.data.data.first_name + " " + res.data.data.last_name + " wurden erfolgreich geändert!";
                vm.$bvToast.toast(message, {
                    title: 'Daten erfolgreich geändert!',
                    autoHideDelay: 10000,
                    appendToast: true,
                    variant: "success",
                    toaster: 'b-toaster-bottom-left'
                })
            })
            .catch(err => ToastNotification.makeErrorToast(vm, err))
    },
    deleteUser({ commit }, { id, vm }) {
        window.axios.delete("/users/" + id)
            .then(() => commit("deleteUser", id))
            .catch(err => ToastNotification.makeErrorToast(vm, err));
    }
};

const mutations = {
    setUserList: (state, userList) => state.userList = userList,
    setSelectedUser: (state, user) => state.selectedUser = user,
    addUser: (state, user) => state.userList.push(user),
    updateUser: (state, user) => {
        const index = state.userList.findIndex(el => el.user_id === user.user_id);

        if (index !== -1) {
            state.userList.splice(index, 1, user);
        }
    },
    deleteUser: (state, id) => state.userList = state.userList.filter(el => el.user_id !== id)
};


export default {
    state,
    getters,
    actions,
    mutations
}
