<template>
  <div :class="['appoint-alert', bgColor]" @click="openEdit">
    <div class="title" v-if="!isAuthenticated">{{ variantsText[variant] }}</div>
    <div :class="['title', bgColor]" v-else v-html="title"></div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "Appoint",
  props: {
    variant: {
      type: Number,
      required: true
    },
    title: {
      type: String
    },
    date: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      variantsText: [
        "Termine verfügbar",
        "Termine verfügbar",
        "KEINE Termine verfügbar"
      ]
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    bgColor() {
      switch (this.variant) {
        case 0:
          return "bg-success";
        case 1:
          return "bg-warning";
        case 2:
          return "bg-disabled";
      }
    }
  },
  methods: {
    ...mapMutations(["setSelectedDate"]),
    openEdit() {
      this.setSelectedDate(this.date);
      if (this.isAuthenticated) {
        this.$root.$emit("bv::toggle::modal", "appointment-list-modal");
      } else if (this.variant < 2) {
        this.$root.$emit("bv::toggle::modal", "appointment-modal");
      } else {
        this.$bvModal.msgBoxOk(
          "An diesem Tag sind keine Termine mehr verfügbar. Bitte wählen Sie einen anderen Tag!",
          {
            title: "Hinweis",
            okVariant: "primary",
            headerClass: "bg-primary text-white",
            centered: true
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" >
.bg-disabled {
  background-color: #a9a9a9 !important;
}
.appoint-alert {
  border-radius: 3px 4px;
  padding: 4px;
  display: flex;
  min-height: 100%;
  justify-content: space-between;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  margin: 5px 0;
  cursor: pointer;
}

.time {
  text-align: left;
  font-weight: bold;
}

.event-popover {
  min-width: 300px;
}

.title {
  text-align: center;
  opacity: 0;
  font-size: 14px;
  width: 100%;
}

@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
  .title {
    opacity: 1;
  }
}
@media screen and (min-width: 992px) {
  .title {
    font-size: 16px;
  }
}
@media screen and (min-width: 1200px) {
}
</style>
