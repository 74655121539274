<template>
  <div>
    <b-row>
      <b-col md="12"><h5>Kontaktdaten</h5></b-col>
    </b-row>
    <b-row>
      <b-col md="6"> Name: </b-col>
      <b-col md="6">{{ fullName }}</b-col>
    </b-row>
    <b-row>
      <b-col md="6"> E-Mail: </b-col>
      <b-col md="6">
        <a class="text-info" :href="'mailto:' + appoint.email">{{
          appoint.email
        }}</a>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6"> Telefon: </b-col>
      <b-col md="6"> {{ appoint.phone }} </b-col>
    </b-row>
    <b-row>
      <b-col md="6"> Adresse: </b-col>
      <b-col md="6" v-html="address"> </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col md="12" class="mt-1"><h5>Beratung</h5></b-col>
    </b-row>
      <b-row>
          <b-col md="6">Folgetermin: </b-col>
          <b-col md="6">{{isFollowup}}</b-col>
      </b-row>
    <b-row>
      <b-col md="6"> Teilnehmer: </b-col>
      <b-col md="6"> {{ appoint.visitor_count }} </b-col>
    </b-row>
    <b-row>
      <b-col md="6"> Beratungswunsch: </b-col>
      <b-col md="6" class="mb-4">
        {{ appoint.message }}
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6"> Beraterauswahl: </b-col>
      <b-col md="6">
        <b-form
          v-if="getAvailableConsultants.length > 1"
          @submit.prevent.stop="updateAppointmentRequest"
        >
          <b-row>
            <b-col>
              <b-form-radio-group
                stacked
                v-model="selectedConsultant"
                :options="getAvailableConsultants"
              ></b-form-radio-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-1">
              <b-button variant="success" size="sm" type="submit">
                Berater bestätigen
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <p v-else class="text-primary">Keine Berater verfügbar!</p>
      </b-col>
    </b-row>
      <b-row v-if="appoint.note !== null && appoint.note !== ''" class="my-3 internal-notice">
          <b-col md="6">
              Interne Notiz
          </b-col>
          <b-col md="6">
              {{appoint.note}}
          </b-col>
      </b-row>
    <b-row>
      <b-col md="3">
        <b-button variant="primary" @click="deleteRequest">Termin stornieren</b-button>
      </b-col>
      <!-- <b-col md="3">
        <b-button variant="info">Termin bearbeiten!</b-button>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import VueGallerySlideshow from "vue-gallery-slideshow";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "AppointListItem",
    components:{
      VueGallerySlideshow
    },
    data(){
      return {
          index:null
      }
    },
  props: {
    appoint: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getConsultants", "getAppointmentsOnHour", "getAvailableConsultantsForTimeslotOnDate"]),
      isFollowup(){
        return this.appoint.is_followup ? "Ja": "Nein";
      },
    fullName() {
      let salutation = "";
      switch (this.appoint.gender) {
        case "m" || "Herr":
          salutation = "Herr ";
          break;
        case "w" || "Frau":
          salutation = "Frau ";
          break;
        case "d":
          break;
      }
      let title = this.appoint.title === null ? "" : this.appoint.title;
      return (
        salutation +
        " " +
        title +
        " " +
        this.appoint.first_name +
        " " +
        this.appoint.last_name
      );
    },
      images(){

        return this.appoint.images.map(img => {
            return img.url;
        });
      },
    getAvailableConsultants() {
      let consultantOptions = [
        {
          text: "kein Berater",
          value: null
        }
      ];

      consultantOptions.push(...this.getAvailableConsultantsForTimeslotOnDate(this.appoint.date, this.appoint.time).map(consultant => {
          return {
              text: consultant.first_name + " " + consultant.last_name,
              value: consultant.consultant_id
          };
      }));

        return consultantOptions;
    },
    address() {
      if (
        this.appoint.zipcode === null ||
        this.appoint.city === null ||
        this.appoint.street === null
      )
        return "Keine Adresse angegeben!";
      return (
        this.appoint.street +
        "<br>" +
        this.appoint.zipcode +
        " " +
        this.appoint.city
      );
    },
    selectedConsultant: {
      get() {
        if (this.appoint.consultant === null || this.appoint.consultant === undefined) return null;

        return this.appoint.consultant.consultant_id;
      },
      set(value) {
        this.appoint.consultant_id = value;
      }
    }
  },
  methods: {
    ...mapActions(["updateAppointment", "deleteAppointment"]),
    ...mapMutations(["setSelectedAppointment"]),
    updateAppointmentRequest() {
      this.setSelectedAppointment(Object.assign({}, this.appoint));
      this.updateAppointment({ vm: this });
    },
    deleteRequest() {
      if (
        confirm(
          "Möchten Sie den Termin (" +
            this.appoint.appointment_id +
            ") wirklich stornieren? Er wird dann aus der Datenbank dauerhaft gelöscht!"
        )
      ) {
        this.setSelectedAppointment(Object.assign({}, this.appoint));
        this.deleteAppointment({ vm: this });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img-gallery-preview{
    &:hover{
        cursor: pointer;
    }
}
.internal-notice{
    background:rgba(0,0,0,0.1);
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    border-top: 2px solid rgba(0,0,0,0.3);
}
</style>
