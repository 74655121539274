import Vue from "vue";

import Router from 'vue-router';

import Main from "./views/Main.vue";
import Login from "./views/Login.vue";
import User from "./views/User.vue";
import Profile from "./views/Profile.vue";
import VierNullVier from "./views/404.vue";
import AppointVerification from "./views/AppointVerification.vue"
import ConsultantManager from "./views/ConsultantManager.vue";
import AbsenceManager from "./views/AbsenceManager.vue";
import AppointOverview from "./views/AppointOverview.vue";
import AppointmentVisitorView from "./views/AppointmentVisitorView";
import ReviewCriteria from "./views/ReviewCriteria";
import store from "./store/index";
import Review from "./views/Review";


Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [{
        path: '/',
        name: 'calendar',
        component: Main
    },
        {
            path: '/login',
            name: "login",
            component: Login
        },
        {
            path: '/users',
            name: 'users',
            component: User,
            beforeEnter: (to, from, next) => {
                navigationGuard(to, from, next);
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            beforeEnter: (to, from, next) => {
                navigationGuard(to, from, next);
            }
        },
        {
            path: '/overview',
            name: 'overview',
            component: AppointOverview,
            beforeEnter: (to, from, next) => {
                navigationGuard(to, from, next);
            }
        },
        {
            path: '/consultants',
            name: 'consultants',
            component: ConsultantManager,
            beforeEnter: (to, from, next) => {
                navigationGuard(to, from, next);
            }
        },
        {
            path: '/absence',
            name: 'absence',
            component: AbsenceManager,
            beforeEnter: (to, from, next) => {
                navigationGuard(to, from, next);
            }
        },
        {
            path: '/appointments/:appointmentId',
            name: 'appointments',
            props:
                route => (
                    {
                        token: route.query.tmp_token,
                        appointmentId: route.params.appointmentId
                    }
                ),
            component: AppointmentVisitorView,
            beforeEnter: (to, from, next) => {
                if (to.query.tmp_token === undefined) {
                    next({path: "/"})
                } else {
                    next()
                }
            }
        },
        {
            path: "/appointments/:appointmentId/review",
            name: "review",
            props: route => ({
                token: route.query.tmp_token,
                appointmentId: route.params.appointmentId
            }),
            component: Review,
            beforeEnter: (to, from, next) => {
                if (to.query.tmp_token === undefined) {
                    next({path: "/"})
                } else {
                    next()
                }
            }
        },
        {
            path: "/review-criteria",
            name: "review-criteria",
            props:
                route => ({
                    token: route.query.tmp_token
                }),
            component: ReviewCriteria,
            beforeEnter: (to, from, next) => {
                navigationGuard(to, from, next);
            }

        },

        {
            path: '*',
            name: '404',
            component: VierNullVier
        },

    ]
});


router.beforeEach((to, from, next) => {
    autoLogin();
    next();
})

function navigationGuard(to, from, next) {
    let auth = store.getters.isAuthenticated;
    if (autoLogin()) {
        next();
        return;
    }
    ;
    if (!auth) {
        next({
            path: "/login"
        });
    } else {
        next();
    }
}


function autoLogin() {
    const token = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    const exp = new Date(localStorage.getItem("exp"));
    const now = new Date();
    if (!token) {
        return false;
    } else if (exp.getTime() > now.getTime()) {
        if (!store.getters.isAuthenticated) {
            store.commit("setLoggedInUser", user);
        }
        return true;
    } else {
        localStorage.clear();
        return false;
    }
}

export default router;
