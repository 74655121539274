import Vue from 'vue';
import Vuex from 'vuex';

import user from "./modules/user";
import auth from "./modules/auth";
import appointment from "./modules/appointment";
import calendar from "./modules/calendar";
import consultant from "./modules/consultant";
import reviewCriteria from "./modules/reviewCriteria";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        calendar,
        user,
        auth,
        appointment,
        consultant,
        reviewCriteria
    }
});
