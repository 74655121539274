<template>
<div class="criteria-item">
    <b-row>
        <b-col md="7" class="criteria-item__main">
            <div class="criteria-item__title">
                <h5>{{item.display_text}}</h5>
                <div class="ml-auto small font-italic">Zuletzt aktualisiert {{item.updated}}</div>
            </div>
            <div class="criteria-item__info">
                <b-badge variant="info">{{item.subject}}</b-badge>
                <b-badge variant="light">{{item.order}}</b-badge>
            </div>
        </b-col>
        <b-col md="1">
            <ReviewCriteriaListItemActions :item="item"/>
        </b-col>
        <b-col md="4">
            <b-row>
                <b-col md="6" >
                    <span class="font-weight-bold">Textfeld:</span> <b-icon :icon="isTextIcon" :variant="isTextVariant"></b-icon>
                </b-col>
                <b-col md="6">
                    <span class="font-weight-bold">Aktiv:</span> <b-icon :icon="isActiveIcon" :variant="isActiveVariant"></b-icon>
                </b-col>
                <b-col md="6" >
                    <span v-if="!item.is_text">Durchschnittliche Bewertung: {{avgScore}} <b-icon-star-fill variant="warning"></b-icon-star-fill></span>
                </b-col>
                <b-col md="6">
                    <span>Bewertungen Insgesamt: {{item.scores.length}}</span>
                </b-col>
                <b-col md="6" v-if="!item.is_text">
                    <a :id="'popover-details-' + item.id" size="sm" tabindex="0" href="#" >Details</a>
                    <b-popover :target="'popover-details-' + item.id" triggers="focus" placement="right">
                        <CountingStarBar :count="n+1" :item="item" v-for="n in reverseKeys(5)" :key="n"></CountingStarBar>
                    </b-popover>
                </b-col>
            </b-row>
        </b-col>

    </b-row>
</div>
</template>

<script>
import CountingStarBar from "./CountingStarBar";
import ReviewCriteriaListItemActions from "./ReviewCriteriaListItemActions";
export default {
    name: "ReviewCriteriaListItem",
    components: {ReviewCriteriaListItemActions, CountingStarBar},
    props:[
        "item"
    ],
    methods:{
        reverseKeys(n) {
            return [...Array(n).keys()].slice().reverse()
        }
    },
    computed:{
        isTextIcon()
        {
            return (this.item.is_text? "check-square-fill" : "x-square-fill");
        },
        isTextVariant()
        {
            return (this.item.is_text? "success" : "danger");
        },
        isActiveIcon()
        {
            return (this.item.is_active? "check-square-fill" : "x-square-fill");
        },
        isActiveVariant()
        {
            return (this.item.is_active? "success" : "danger");
        },
        avgScore(){
            if(!this.item.is_text && this.item.scores.length > 0)
            {
                let sum = 0;
                this.item.scores.forEach(score => {
                   sum += score.score;
                });
                return (sum / this.item.scores.length).toFixed(1);
            }
            return null
        }
    }
}
</script>

<style scoped lang="scss">
.criteria-item{
    margin:1rem;
    .criteria-item__main{
        padding:1rem;
        border: 1px solid rgba(0,0,0,0.1);
        background:rgba(0,0,0,0.05);
    }
    .criteria-item__title, .criteria-item__info{
        display:flex;
        flex-direction:row;
        justify-content: space-between;
    }

}
</style>
