<template>
  <b-row class="my-3">
    <b-table
      striped
      dark
      :fields="fields"
      :items="getUserList"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #cell(email)="row">
        <a :href="'mailto:' + row.value">{{ row.value }}</a>
      </template>
      <template #cell(is_admin)="row">
        <p class="h3">
          <b-icon-check v-if="row.value" variant="success" />
          <b-icon-x-circle-fill v-else variant="danger" />
        </p>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="md"
          class="mr-2"
          variant="outline-info"
          v-b-tooltip.hover
          title="Bearbeiten"
          @click="editUser(row.item)"
        >
          <b-icon-pencil-fill></b-icon-pencil-fill>
        </b-button>
        <b-button
          size="md"
          class="mr-2"
          v-b-tooltip.hover
          title="Events ansehen"
          variant="outline-success"
          @click="showUserEvents(row.item)"
        >
          <b-icon-eye-fill></b-icon-eye-fill>
        </b-button>
        <b-button
          size="md"
          class="mr-2"
          variant="outline-danger"
          v-b-tooltip.hover
          title="User löschen"
          @click="deleteUserRequest(row.item)"
          v-if="row.item.user_id !== getLoggedInUserId"
        >
          <b-icon-trash-fill></b-icon-trash-fill>
        </b-button>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "UserTable",
  components: {},
  props: {},
  data() {
    return {
      sortBy: "user_id",
      sortDesc: false,
      fields: [
        {
          key: "user_id",
          label: "ID",
          sortable: true
        },
        {
          key: "first_name",
          label: "Vorname",
          sortable: true
        },
        {
          key: "last_name",
          label: "Nachname",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "is_admin",
          label: "Adminrechte",
          sortable: true
        },
        {
          key: "actions",
          label: "Aktionen",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getUserList", "getLoggedInUserId"])
  },
  methods: {
    ...mapMutations(["setSelectedUser"]),
    ...mapActions(["deleteUser"]),
    editUser(user) {
      this.setSelectedUser(Object.assign({}, user));
      this.$root.$emit("bv::toggle::collapse", "user-sidebar");
    },
    deleteUserRequest(item) {
      if (
        confirm(
          "User '" + item.first_name + " " + item.last_name + "' löschen?"
        )
      ) {
        this.deleteUser({ id: item.user_id, vm: this });
      }
    },
    showUserEvents(user) {
      this.setSelectedUser(Object.assign({}, user));
      this.$router.push("/");
    }
  }
};
</script>

<style>
</style>
