<template>

</template>

<script>
export default {
    name: "CriteriaScoreFormField",
    data()
    {
      return {
      }
    },
    props:[
        "criteria",
        "value"
    ],
    computed:{

    },
    methods:{
        handleInput(e)
        {
            this.$emit("input", this.value);
        }
    }
}
</script>

<style scoped>

</style>
