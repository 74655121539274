<template>
    <b-button-group vertical>
        <b-button size="sm" variant="outline-info" @click="requestEditForm"><b-icon-pen-fill></b-icon-pen-fill></b-button>
    </b-button-group>
</template>

<script>

import {eventBus} from "../../eventBus";
export default {
    name: "ReviewCriteriaListItemActions",
    props:[
        "item"
    ],
    methods:{
        requestEditForm()
        {
            eventBus.$emit("REVIEW_CRITERIA_FORM_MODAL_OPEN", this.item);
        }
    }
}
</script>

<style scoped>

</style>
