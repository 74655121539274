<template>
    <b-container fluid>
        <Navigation></Navigation>
        <b-container class="content-wrapper">
            <b-overlay :show="showOverlay">
                <router-view></router-view>
            </b-overlay>
        </b-container>
        <Footer></Footer>
    </b-container>
</template>

<script>
import Navigation from "./general/Navigation";
import Footer from "./general/Footer";
import {eventBus} from "../eventBus";

import {mapActions, mapGetters} from "vuex";

export default {
    name: "App",
    data(){
      return {
          showOverlay:false,
      }
    },
    components: {
        Navigation,
        Footer
    },
    props: [
    ],
    computed: {...mapGetters(["isAuthenticated"])},
    methods: {
        ...mapActions(["fetchConsultants"]),
        disableOverlay()
        {
            this.showOverlay = false;
        },
        enableOverlay()
        {
            this.showOverlay = true;
        }
    },
    created() {
        eventBus.$on("ENABLE_OVERLAY", this.enableOverlay.bind(this));
        eventBus.$on("DISABLE_OVERLAY", this.disableOverlay.bind(this));
        if (this.isAuthenticated) {
            this.fetchConsultants({vm: this});
        }
    }
};
</script>

<style lang="scss">
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 700;
}

html {
    background: #868686;
}

.container-fluid {
    padding: 0px;
    background: #868686;
}

.content-wrapper {
    margin-top: 116px;
    min-height: calc(100vh - 325px);
    background: #868686;
}

.content {
    box-shadow: 1px 4px 18px -8px rgb(0 0 0);
    border-radius: 5px;
    background: #fff;
}
</style>
