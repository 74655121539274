<template></template>

<script>
export default {
  name: "AppointVerification"
};
</script>

<style lang="scss" scoped>
</style>
