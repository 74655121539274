export const ToastNotification = {
    makeErrorToast: function (vm, err) {
        vm.$bvToast.toast(err, {
            title: 'Uups da lief was schief!',
            autoHideDelay: 10000,
            appendToast: true,
            variant: "danger",
            toaster: 'b-toaster-bottom-left'
        })
    },
    makeSuccessToast: function (vm, msg) {
        vm.$bvToast.toast(msg, {
            title: 'Success!',
            autoHideDelay: 10000,
            appendToast: true,
            variant: "success",
            toaster: 'b-toaster-bottom-left'
        })
    }
};
