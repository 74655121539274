<template>
  <b-sidebar
    id="user-sidebar"
    right
    shadow
    title="User bearbeiten"
    backdrop
    width="28%"
    bg-variant="dark"
    text-variant="light"
    v-on:hidden="resetSelectedUser"
  >
    <b-container>
      <b-form @submit.prevent="pushUpdatedUser">
        <b-form-group
          label="Name"
          label-for="name-fields"
          description="Der Name des Users"
        >
          <b-input-group class="mb-2" id="name-fields">
            <b-form-input
              type="text"
              v-model="first_name"
              aria-label="Vorname"
              placeholder="Vorname"
            ></b-form-input>
            <b-form-input
              type="text"
              v-model="last_name"
              aria-label="Nachname"
              placeholder="Nachname"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="email-field"
          description="Die Email des Users"
        >
          <b-input
            type="email"
            placeholder="Email"
            id="email-field"
            v-model="email"
          ></b-input>
        </b-form-group>

        <b-form-group
          label="Geburtsdatum"
          label-for="birtday-field"
          description="Das Geburtsdatum des Users"
        >
          <b-form-input
            type="date"
            required
            v-model="birthday"
            id="birthday"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Passwort"
          label-for="password-field"
          description="Das Passwort des Users"
        >
          <b-input
            type="password"
            placeholder="Passwort"
            id="password-field"
            v-model="password"
          ></b-input>
        </b-form-group>
        <b-form-group>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="adminSwitch"
              v-model="adminValue"
              :checked="adminValue"
            />
            <label class="custom-control-label" for="adminSwitch"
              >Administrator</label
            >
          </div>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="activeSwitch"
              v-model="active"
              :checked="active"
            />
            <label class="custom-control-label" for="activeSwitch">Aktiv</label>
          </div>
        </b-form-group>

        <b-button type="submit" variant="success">Speichern</b-button>
      </b-form>
    </b-container>
  </b-sidebar>
</template>

<script>

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "UserEditSidebar",
  data() {
    return {
      password: ""
    };
  },
  computed: {
    ...mapGetters(["getSelectedUser", "getLoggedInUserId"]),
    first_name: {
      get() {
        return this.getSelectedUser.first_name;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.first_name = value;
        this.setSelectedUser(u);
      }
    },
    last_name: {
      get() {
        return this.getSelectedUser.last_name;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.last_name = value;
        this.setSelectedUser(u);
      }
    },
    email: {
      get() {
        return this.getSelectedUser.email;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.email = value;
        this.setSelectedUser(u);
      }
    },
    birthday: {
      get() {
        return this.getSelectedUser.birthday;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.birthday = value;
        this.setSelectedUser(u);
      }
    },
    adminValue: {
      get() {
        return this.getSelectedUser.is_admin;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.is_admin = value;
        this.setSelectedUser(u);
      }
    },
    active: {
      get() {
        return this.getSelectedUser.is_active;
      },
      set(value) {
        let u = Object.assign({}, this.getSelectedUser);
        u.is_active = value;
        this.setSelectedUser(u);
      }
    }
  },
  methods: {
    ...mapMutations(["setSelectedUser", "inputIsAdmin"]),
    ...mapActions(["updateUser", "createUser"]),
    pushUpdatedUser() {
      if (this.password !== "") {
        let u = Object.assign({}, this.getSelectedUser);
        u.password = this.password;
        this.password = "";
        this.setSelectedUser(u);
      }
      if (this.getSelectedUser.user_id === undefined) {
        this.createUser({vm: this});
      } else {
        if(this.getSelectedUser.user_id === this.getLoggedInUserId){

        }
        this.updateUser({vm: this});
      }
      this.$root.$emit("bv::toggle::collapse", "user-sidebar");
    },
    resetSelectedUser() {
      this.setSelectedUser({});
    }
  }
};
</script>

<style>
</style>
