<template>
  <b-container class="mb-3">
    <b-row>
      <b-button variant="success" @click="addUser">User erstellen</b-button>
    </b-row>
    <UserTable></UserTable>
    <UserEditSidebar></UserEditSidebar>
  </b-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import UserTable from "../components/user/UserTable";
import UserEditSidebar from "../components/user/UserEditSidebar";

export default {
  name: "User",
  components: {
    UserTable,
    UserEditSidebar
  },
  methods: {
    ...mapActions(["fetchUserList"]),
    ...mapMutations(["setSelectedUser"]),
    addUser() {
      let u = {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        birthday: "",
        is_active: true,
        is_admin: false
      };
      this.setSelectedUser(u);
      this.$root.$emit("bv::toggle::collapse", "user-sidebar");
    }
  },
  mounted() {
    this.fetchUserList({vm: this});
  }
};
</script>

<style>
</style>
