<template>
    <b-container class="content mb-3 p-5" id="review-content-wrapper">
        <div v-if="!submitted">
            <h1>Bitte bewerten Sie Ihren Beratungstermin</h1>
            <b-form v-if="getCriteria" @submit="handleSubmit">
                <template v-for="(criteriaScore,idx) in getCriteria" >
                    <hr v-if="idx > 0">
                    <b-form-group
                        :key="criteriaScore.id"
                        :label="criteriaScore.display_text"
                        :label-for="inputId(criteriaScore.id)"
                        :label-cols="labelCols(criteriaScore)"
                    >
                        <b-form-rating :id="inputId(criteriaScore.id)" v-if="!criteriaScore.is_text" variant="warning"
                                       v-model="form[criteriaScore.id]" size="lg"></b-form-rating>
                        <b-form-textarea :id="inputId(criteriaScore.id)" v-else
                                         v-model="form[criteriaScore.id]"></b-form-textarea>
                    </b-form-group>
                </template>
                <b-button type="submit" variant="success">Abschicken</b-button>
            </b-form>
        </div>
        <div v-else>
            <h1>Vielen Dank für Ihre Rückmeldung!</h1>
            <p>Wir hoffen, Sie bald wieder begrüßen zu dürfen.</p>
        </div>
    </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import CriteriaScoreFormField from "../components/Review/CriteriaScoreFormField";

export default {
    name: "Review",
    data() {
        return {
            submitted:false,
            form: {
            }
        }
    },
    props:[
        "token"
    ],
    components: {
        CriteriaScoreFormField
    },
    methods: {
        ...mapActions(["fetchCriteria", "submitScores"]),
        inputId(id) {
            return "input-" + id;
        },
        labelCols(criteria) {
            return criteria.is_text ? null : 6;
        },
        handleSubmit(e) {
            e.preventDefault();

            const payload = {
                formData: [],
                appointmentId: this.$route.params.appointmentId,
                token: this.token
            };
            for (let item in this.form) {
                payload.formData.push({
                    review_criteria_id: item,
                    score: _.isNumber(this.form[item]) ? this.form[item] : null,
                    text:  _.isNumber(this.form[item]) ? null : this.form[item]
                });
            }
            this.submitScores(payload)
            .then((res)=>{
                this.submitted = true;
                this.saveToLocalStorage();
            });
        },
        saveToLocalStorage(appointmentId){
            localStorage.setItem("review", JSON.stringify({submitted:this.submitted}));
        },
        loadFromLocalStorage(){
            const storageString = localStorage.getItem("review");
            if(storageString != null)
            {
                let storageData = JSON.parse(storageString);
                this.submitted = storageData.submitted;
            }
        }
    },
    computed: {
        ...mapGetters(["getCriteria"]),


    },
    mounted() {
        this.loadFromLocalStorage();
        this.fetchCriteria();
    }
}
</script>

<style scoped lang="scss">
#review-content-wrapper{
    max-width:750px;
    h1{
        font-size: 30px;
    }
    input[type="text"],
    textarea{
        border: 1px #cecece;
        background: #f1f1f1;
        box-shadow: none;
    }
}
</style>
