<template>
  <b-card class="login-card" title="Login">
    <b-card-body>
      <p v-show="loginFailed" class="text-danger">
        Die Emailadresse oder das Passwort sind nicht korrekt!
      </p>
      <b-form @submit.prevent.stop="submitLogin">
        <b-form-group label="E-Mail-Adresse" label-for="email">
          <b-form-input
            type="email"
            placeholder="E-Mail"
            required
            v-model="email"
            id="email"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group label="Passwort" label-for="password">
          <b-form-input
            type="password"
            required
            v-model="password"
            id="password"
            placeholder="Passwort"
          >
          </b-form-input>
        </b-form-group>
        <b-row>
          <b-col md="6">
            <b-button type="submit"> Login </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      loginFailed: false
    };
  },
  methods: {
    ...mapActions(["login", "autoLogin"]),
    submitLogin() {
      let inst = this;
      const payload = {
        data: {
          email: this.email,
          password: this.password
        },
        instance: this
      };
      this.login(payload);
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.login-card {
  width: 50%;
  margin: auto;
  box-shadow: 1px 4px 18px -1px rgba(0, 0, 0, 1);
}
</style>
