<template>
  <b-container class="m-3 content">
    <b-row>
      <b-col class="text-center my-3">
        <h2>Beraterverwaltung</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="success" @click="addConsultant">
          Berater erstellen
        </b-button>
      </b-col>
    </b-row>
    <ConsultantTable></ConsultantTable>
    <ConsultantEditSidebar></ConsultantEditSidebar>
  </b-container>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import ConsultantTable from "../components/consultants/ConsultantTable";
import ConsultantEditSidebar from "../components/consultants/ConsultantEditSideBar";

export default {
  name: "ConsultantManager",
  components: {
    ConsultantTable,
    ConsultantEditSidebar
  },
  methods: {
    ...mapActions(["fetchConsultants"]),
    ...mapMutations(["setSelectedConsultant"]),
    addConsultant() {
      let u = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        avatar_url: "",
        description: ""
      };
      this.setSelectedConsultant(u);
      this.$root.$emit("bv::toggle::collapse", "consultant-sidebar");
    }
  },
  mounted() {
    this.fetchConsultants({ vm: this });
  }
};
</script>

<style>
</style>
