<template>
    <b-card no-body>
        <b-card-title @click="visible = !visible" title-tag="div" class="m-3">
            <div class="title-main">
            <b-icon :icon="caretDirection"></b-icon>
            <slot name="title-icon"></slot>
            <span>{{ title }}</span>
            </div>
            <slot name="post-title"></slot>
        </b-card-title>
        <b-collapse v-model="visible" :id="id">
            <b-card-body>
                <slot></slot>
            </b-card-body>
        </b-collapse>
    </b-card>
</template>

<script>
import {eventBus} from "../../eventBus";

export default {
    name: "CollapsableCard",
    props: {
        id: {
            required: true
        },
        title: {
            type: String,
            default: () => {
                return "Titel";
            }
        },
        tag: {
            type: String,
            default:() => {
                return "";
            }
        }

    },
    data() {
        return {
            visible: false
        };
    },
    computed: {
        caretDirection() {
            return this.visible ? "caret-down-fill" : "caret-right-fill";
        }
    },
    methods: {
        onCollapsableToggleBtnClick(tag){
            if(this.tag === tag)
            {
                this.visible = !this.visible;
            }
        }
    },
    mounted() {
        eventBus.$on("TOGGLE_COLLAPSABLE_BUTTON_CLICKED",this.onCollapsableToggleBtnClick)
    }
};
</script>

<style lang="scss" scoped>
.card-title {
    cursor: pointer;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}
.title-main{

}
</style>
