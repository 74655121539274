const state = {
    from: {},
    to: {},
    selectedDate: new Date()
};

const getters = {
    getFrom: state => state.from,
    getTo: state => state.to,
    getSelectedDate: state => state.selectedDate,
};

const mutations = {
    setFrom: (state, from) => state.from = from,
    setTo: (state, to) => state.to = to,
    setSelectedDate: (state, date) => state.selectedDate = date
}

export default {
    state,
    getters,
    mutations
}
