<template>
<div v-if="reviewCriteria.length > 0">
    <b-tabs>
        <b-tab title="Aktiv" active>
            <ReviewCriteriaListItem v-for="item in activeItems" :key="item.id" :item="item"></ReviewCriteriaListItem>
        </b-tab>
        <b-tab title="Inaktiv">
            <ReviewCriteriaListItem v-for="item in inactiveItems" :key="item.id" :item="item"></ReviewCriteriaListItem>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import ReviewCriteriaListItem from "./ReviewCriteriaListItem";
export default {
    name: "ReviewCriteriaList",
    props:[
      "reviewCriteria"
    ],
    components:{
        ReviewCriteriaListItem
    },
    computed:{
        activeItems()
        {
            return this.reviewCriteria.filter(item=>{
               return item.is_active;
            });
        },
        inactiveItems()
        {
            return this.reviewCriteria.filter(item=> {
                return !item.is_active;
            })
        }
    }
}
</script>

<style scoped>

</style>
