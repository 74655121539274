<template>
  <b-container class="content mb-3">
    <b-row class="mt-3">
      <b-col class="text-center mt-3">
        <h2>Terminübersicht</h2>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col>
        <b-nav>
          <b-nav-form>
            <b-form-input
              type="date"
              v-model="from"
              v-b-popover.hover.bottom="'Termine von:'"
              class="m-1"
            >
            </b-form-input>
            <b-form-input
              type="date"
              v-model="to"
              v-b-popover.hover.bottom="'Termine bis:'"
              class="m-1"
            >
            </b-form-input>
          </b-nav-form>
          <b-nav-item-dropdown :text="consultantDropdownText">
            <b-dropdown-item @click="setConsultant('all')">
              Alle Berater ansehen
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(cons, index) in getConsultants"
              :key="index"
              @click="setConsultant(cons.consultant_id)"
            >
              {{ fullName(cons) }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-nav>
      </b-col>
    </b-row>
    <AppointmentTable></AppointmentTable>
  </b-container>
</template>

<script>
import AppointmentTable from "../components/appointments/AppointmentTable.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "AppointOverview",
  components: { AppointmentTable },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "getFrom",
      "getTo",
      "getSelectedConsultant",
      "getConsultants"
    ]),

    from: {
      get() {
        return formatDate(this.getFrom);
      },
      set(value) {
        this.setFrom(new Date(value));
        this.fetchAppointmentsForUser({ vm: this });
      }
    },
    to: {
      get() {
        return formatDate(this.getTo);
      },
      set(value) {
        this.setTo(new Date(value));
        this.fetchAppointmentsForUser({ vm: this });
      }
    },
    consultantDropdownText() {
      return Object.keys(this.getSelectedConsultant).length > 0
        ? this.fullName(this.getSelectedConsultant)
        : "Beraterauswahl";
    }
  },
  methods: {
    ...mapMutations(["setTo", "setFrom", "setSelectedConsultant"]),
    ...mapActions(["fetchAppointmentsForUser", "fetchConsultants"]),
    fullName(consultant) {
      return consultant.first_name + " " + consultant.last_name;
    },
    setConsultant(id) {
      let selectedConsultant = {};
      if (id !== "all") {
        selectedConsultant = this.getConsultants.find(
          el => el.consultant_id === id
        );
      }
      this.setSelectedConsultant(selectedConsultant);
      this.fetchAppointmentsForUser({ vm: this });
    }
  },
  created() {
    const date = new Date();
    date.setDate(1);
    this.setFrom(new Date(date));
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    this.setTo(date);
    this.fetchAppointmentsForUser({ vm: this });
    this.fetchConsultants({ vm: this });
  }
};
</script>

<style lang="scss">
input[type="date"] {
  background-color: #f1f1f1;
  border-radius: 5px;
  height: auto;
  padding: 0px;
}
</style>
