<template>
  <b-navbar toggleable="lg" type="light" fixed="top" variant="light">
    <b-navbar-brand to="/">
      <img
        src="/img/ruhe-atp.png"
        alt="Ruhe & Co."
        class="d-inline-block align-top"
      />
    </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item to="/" exact exact-active-class="active" v-if="isAuthenticated">
          Terminplaner
        </b-nav-item>
        <b-nav-item
          to="/overview"
          exact
          exact-active-class="active"
          v-if="isAuthenticated"
        >
          Terminübersicht
        </b-nav-item>
        <b-nav-item-dropdown right v-if="isAuthenticated">
          <template #button-content>Verwaltung</template>
          <b-dropdown-item to="/consultants">Beraterverwaltung</b-dropdown-item>
          <b-dropdown-item to="/absence">
            Abwesenheitsverwaltung
          </b-dropdown-item>
            <b-dropdown-item to="/review-criteria">
                Bewertungen
            </b-dropdown-item>
        </b-nav-item-dropdown>
        <!--                <b-nav-item to="/users" exact exact-active-class="active">-->
        <!--                    Hilfe-->
        <!--                </b-nav-item>-->
        <b-nav-item-dropdown right v-if="isAuthenticated">
          <template #button-content>Profil</template>
          <b-dropdown-item to="/profile">Profil</b-dropdown-item>
          <b-dropdown-item @click="logoutAction">Abmelden</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Navigation",
  computed: {
    ...mapGetters(["isAdmin", "getLoggedInUser", "isAuthenticated"]),
    userName() {
      return (
        this.getLoggedInUser.first_name + " " + this.getLoggedInUser.last_name
      );
    },
  },
  methods: {
    ...mapActions(["logout"]),
    logoutAction() {
      this.logout();
    },
  },
};
</script>

<style scoped lang="scss">
.active {
  color: #d10007 !important;
}
.navbar {
  max-height: 100px;
}
</style>
