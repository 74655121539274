<template>
  <b-sidebar
    id="consultant-sidebar"
    right
    shadow
    title="Berater bearbeiten"
    backdrop
    width="28%"
    v-on:hidden="resetSelectedConsultant"
  >
    <b-container>
      <b-form @submit.prevent="pushUpdatedConsultant">
        <b-form-group
          label="Name"
          label-for="name-fields"
          description="Der Name des Beraters"
        >
          <b-input-group class="mb-2" id="name-fields">
            <b-form-input
              type="text"
              v-model="first_name"
              aria-label="Vorname"
              placeholder="Vorname"
            ></b-form-input>
            <b-form-input
              type="text"
              v-model="last_name"
              aria-label="Nachname"
              placeholder="Nachname"
            ></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group
          label="E-Mail"
          label-for="email-field"
          description="Die E-Mail des Beraters"
        >
          <b-input
            type="email"
            placeholder="E-Mail"
            id="email-field"
            v-model="email"
          ></b-input>
        </b-form-group>

        <b-form-group
          label="Telefon"
          label-for="phone-field"
          description="Die interne Telefonnummer des Beraters"
        >
          <b-form-input
            type="tel"
            required
            v-model="phone"
            placeholder="+49 1234 567890"
            id="phone-field"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Avatarlink"
          label-for="avatar-field"
          description="Link zum Profilbild des Beraters"
        >
          <b-form-input
            type="text"
            required
            placeholder="https://link-zum-bild.com"
            v-model="avatar_url"
            id="avatar-field"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-for="description-area"
          description="Zuständigkeit des Beraters"
        >
          <b-form-textarea
            placeholder="Anmerkung zum Berater"
            rows="6"
            id="description-area"
            v-model="description"
          >
          </b-form-textarea>
        </b-form-group>
        <b-button type="submit" variant="success">Speichern</b-button>
      </b-form>
    </b-container>
  </b-sidebar>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ConsultantEditSidebar",
  data() {
    return {
      password: ""
    };
  },
  computed: {
    ...mapGetters(["getSelectedConsultant"]),
    first_name: {
      get() {
        return this.getSelectedConsultant.first_name;
      },
      set(value) {
        this.changeConsultantAttribute("first_name", value);
      }
    },
    last_name: {
      get() {
        return this.getSelectedConsultant.last_name;
      },
      set(value) {
        this.changeConsultantAttribute("last_name", value);
      }
    },
    email: {
      get() {
        return this.getSelectedConsultant.email;
      },
      set(value) {
        this.changeConsultantAttribute("email", value);
      }
    },
    phone: {
      get() {
        return this.getSelectedConsultant.phone;
      },
      set(value) {
        this.changeConsultantAttribute("phone", value);
      }
    },
    avatar_url: {
      get() {
        return this.getSelectedConsultant.avatar_url;
      },
      set(value) {
        this.changeConsultantAttribute("avatar_url", value);
      }
    },
    description: {
      get() {
        return this.getSelectedConsultant.description;
      },
      set(value) {
        this.changeConsultantAttribute("description", value);
      }
    }
  },
  methods: {
    ...mapMutations(["setSelectedConsultant"]),
    ...mapActions(["updateConsultant", "createConsultant"]),
    pushUpdatedConsultant() {
      if (this.getSelectedConsultant.consultant_id === undefined) {
        this.createConsultant({ vm: this });
      } else {
        this.updateConsultant({ vm: this });
      }
      this.$root.$emit("bv::toggle::collapse", "consultant-sidebar");
    },
    changeConsultantAttribute(attributeName, value) {
      let consultant = Object.assign({}, this.getSelectedConsultant);
      consultant[attributeName] = value;
      this.setSelectedConsultant(consultant);
    },
    resetSelectedConsultant() {
      this.setSelectedConsultant({});
    }
  }
};
</script>

<style lang="scss" scoped>
input,
textarea {
  border: 1px #cecece;
  background: #f1f1f1;
  box-shadow: none;
}

label {
  color: black;
}
</style>
