<template>
  <b-container class="content mb-3">
    <b-row class="mt-3">
      <b-col class="text-center mt-3">
        <h2>Abwesenheitsverwaltung</h2>
      </b-col>
    </b-row>
    <Calendar />
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import Calendar from "../components/calendar/Calendar.vue";

export default {
  name: "Main",
  components: {
    Calendar
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    ...mapActions(["fetchConsultants"])
  },
  created() {

  }
};
</script>

<style lang='scss'>
.bg-disabled {
  background-color: #a9a9a9 !important;
}
</style>
