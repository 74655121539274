<template>
  <b-row class="my-3">
    <b-table
      :fields="fields"
      :items="getConsultants"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #cell(avatar_url)="row">
        <b-img
          :src="row.value"
          alt="Bild kann nicht angezeigt werden!"
          fluid
          width="100"
        ></b-img>
      </template>
      <template #cell(email)="row">
        <a :href="'mailto:' + row.value">{{ row.value }}</a>
      </template>
      <template #cell(actions)="row">
        <b-button
          size="sm"
          class="m-1"
          variant="info"
          v-b-tooltip.hover
          title="Bearbeiten"
          @click="editConsultant(row.item)"
        >
          <b-icon-pencil-fill></b-icon-pencil-fill>
        </b-button>
        <b-button
          size="sm"
          class="m-1"
          variant="primary"
          v-b-tooltip.hover
          title="Berater löschen"
          @click="deleteConsultantRequest(row.item)"
        >
          <b-icon-trash-fill></b-icon-trash-fill>
        </b-button>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ConsultantTable",
  components: {},
  props: {},
  data() {
    return {
      sortBy: "last_name",
      sortDesc: false,
      fields: [
        {
          key: "avatar_url",
          label: "Profilbild",
          sortable: false
        },
        {
          key: "first_name",
          label: "Vorname",
          sortable: true
        },
        {
          key: "last_name",
          label: "Nachname",
          sortable: true
        },
        {
          key: "email",
          label: "E-Mail",
          sortable: true
        },
        {
          key: "phone",
          label: "Telefon",
          sortable: true
        },
        {
          key: "description",
          label: "Beschreibung",
          sortable: false
        },
        {
          key: "actions",
          label: "Aktionen",
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getConsultants"])
  },
  methods: {
    ...mapMutations(["setSelectedConsultant"]),
    ...mapActions(["deleteConsultant"]),
    editConsultant(consultant) {
      this.setSelectedConsultant(Object.assign({}, consultant));
      this.$root.$emit("bv::toggle::collapse", "consultant-sidebar");
    },
    deleteConsultantRequest(item) {
      if (
        confirm(
          "Berater '" + item.first_name + " " + item.last_name + "' löschen?"
        )
      ) {
        this.deleteConsultant({ id: item.consultant_id, vm: this });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
