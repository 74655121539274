<template>
    <b-modal id="show-reviews-modal" :title="'Bewertungen für den ' + getFormattedDate" size="lg">
        <template #modal-footer="{close,ok}">
            <b-button variant="danger" @click="close()">Abbrechen</b-button>

            <b-button variant="success" @click="ok()">Ok</b-button>
        </template>
            <b-card border-variant="secondary" v-for="review in getReviewsForSelectedDate" class="mb-2" :key="review.appointment_id">
                <template #header>
                    <h5 class="text-center">{{review.time}} - {{review.name}}</h5>
                </template>
                <b-card-body>
                    <ReviewScoresContainer :scores="review.scores"></ReviewScoresContainer>
                </b-card-body>
            </b-card>
    </b-modal>
</template>

<script>
import {mapGetters} from "vuex";
import ReviewScoresContainer from "./ReviewScoresContainer";

export default {
    name: "ShowReviewsModal",
    components:{
      ReviewScoresContainer,
    },
    computed: {
        ...mapGetters(["getReviewsForSelectedDate", "getSelectedDate"]),
        getFormattedDate()
        {
            return window.formatDate(this.getSelectedDate);
        }
    }
}
</script>

<style scoped>
.card-header{
    background:#a9a9a9;
    color:#fff;
}
</style>
