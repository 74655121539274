<template>
    <div>
        <div v-for="score in scores" :key="score.id">
            <h6>{{ score.display_text }}</h6>
            <p v-if="score.score !== null">{{score.score}} <b-icon-star-fill variant="warning"></b-icon-star-fill></p>
            <p v-else>{{score.text}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "ReviewScoresContainer",
    props: ["scores"],
    methods: {
        scoreResult(score) {
            if (score.score === null) {
                return score.text;
            }
            return score.score
        }
    },
}
</script>

<style scoped>

</style>
