<template>
  <b-container class="content mb-3">
    <b-row class="text-white bg-disabled" id="refresh-anchor">
      <b-col class="text-center" id="main-heading">
        <h2>Terminplaner Ausstellung Göttingen</h2>
      </b-col>
        <b-button id="refresh-btn" @click="emitRefreshBtnClicked" v-b-tooltip="'Daten aktualisieren'" v-if="isAuthenticated">
            <b-icon icon="arrow-clockwise"></b-icon>
        </b-button>
    </b-row>
    <Calendar />
    <b-container class="pb-4" v-if="!isAuthenticated">

      <b-row align-h="around" class="legend-boxes-wrapper">
        <b-col md="3" class="bg-success text-white m-1 p-2 rounded text-center">
            An grün markierten Tagen sind noch Termine verfügbar.
        </b-col>
        <b-col md="3" class="bg-warning text-white m-1 p-2 rounded text-center">
            An orange markierten Tagen sind nur noch wenige Termine verfügbar.
        </b-col>
        <b-col md="3" class="bg-disabled text-white m-1 p-2 rounded text-center">
            An grau markierten Tagen sind keine Termine mehr verfügbar.
        </b-col>
      </b-row>
    </b-container>

    <AppointmentModal />
    <AppointmentListModal></AppointmentListModal>
  </b-container>
</template>

<script>
import {eventBus} from "../eventBus";
import AppointmentModal from "../components/appointments/AppointmentModal.vue";
import AppointmentListModal from "../components/appointments/AppointmentListModal.vue";
import Calendar from "../components/calendar/Calendar.vue";
import {mapGetters} from "vuex";

export default {
  name: "Main",
  components: {
    AppointmentModal,
    Calendar,
    AppointmentListModal
  },
  data() {
    return {
        timer:'',
    };
  },
  computed: {
      ...mapGetters([ "isAuthenticated"]),
  },
  methods: {
      emitRefreshBtnClicked()
      {
          eventBus.$emit('REFRESH_BTN_CLICKED');
      },
      cancelAutoUpdate()
      {
          clearInterval(this.timer);
      }
  },
    beforeDestroy() {
      this.cancelAutoUpdate();
    },
    mounted() {
      this.timer = setInterval(this.emitRefreshBtnClicked, 300000 );
    }
};
</script>

<style lang='scss'>
.bg-disabled {
  background-color: #a9a9a9 !important;
}
#main-heading{
    padding:1rem;
    margin-top:0;
    h2{
        font-size:1rem;

        @media screen and(min-width:480px ){
            font-size:1.5rem;
        }
        @media screen and(min-width:768px){
            font-size:2rem;
        }
    }
}
.legend-boxes-wrapper{
    font-size: .8rem;
}

#refresh-anchor{
    position:relative;
}

#refresh-btn{
    position:absolute;
    width:50px;
    height:50px;
    right:14px;
    top:14px;
    color:#fff;
    background:#ff9800;
    &:hover{
        background:#fff;
        color:#575757;
    }
}
</style>
