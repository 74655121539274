import router from "../../router";
import { ToastNotification } from "../exceptionHandler";

const state = {
    loggedInUser: {}
};

const getters = {
    getAuthToken: state => state.loggedInUser.token.value,
    getLoggedInUserId: state => state.loggedInUser.user_id,
    getLoggedInUser: state => state.loggedInUser,
    isAuthenticated: state => Object.keys(state.loggedInUser).length !== 0,
    isAdmin: state => state.loggedInUser.is_admin,
};

const actions = {
    login({ commit }, payload) {
        window.axios.post("/auth/login", payload.data)
            .then(res => {
                commit("setLoggedInUser", res.data.data);
                setSession(res.data.data);
                router.replace("/");
            })
            .catch(err => {
                if (err.response) {
                    if (err.response.status === 401) {
                        payload.instance.loginFailed = true;
                    }
                }
            });
    },
    logout({ commit, getters }) {
        window.axios.post("/auth/logout", {}, {
            headers: {
                Authentication: getters.getAuthToken
            }
        })
            .catch(err => console.log(err));
        commit("setLoggedInUser", {});
        localStorage.clear();
        router.replace("/login");

    }
};

const mutations = {
    setLoggedInUser: (state, user) => state.loggedInUser = user,
};

function setSession(data) {
    localStorage.setItem("token", data.token.value);
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("exp", data.token.expires);
}

export default {
    state,
    getters,
    actions,
    mutations
}
