<template>
    <b-modal
        id="appointment-modal"
        scrollable
        size="lg"
        :title="'Terminwunsch ' + date"
        hide-footer
        v-model="show"
        @close="resetValuesAndClose"
    >

        <b-container>

            <b-form
                @submit.prevent.stop="sendAppointment"
                @reset.prevent.stop="resetValuesAndClose"
            >
                <p class="text-center">
                    Notwendige Felder sind <span class="this-required">mit</span> markiert
                </p>
                <b-form-group>
                    <h4 class="this-required">Gewünschte Uhrzeit:</h4>
                    <b-form-radio-group
                        :options="appointmentTimeOptions"
                        v-model="form.appointmentTime.value"
                        required
                        size="sm"
                    />
                </b-form-group>
                <b-form-group>
                    <b-form-checkbox
                        v-model="form.isFollowup.value"
                    >
                        Es handelt sich um einen Folgetermin.
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Bitte wählen Sie Ihren Wunsch-Berater aus:">
                    <b-form-select v-model="form.consultant_id.value" :options="consultantOptions">

                    </b-form-select>
                </b-form-group>
                <h4>Persönliche Informationen</h4>
                <b-form-group label="Anrede:">
                    <b-form-radio-group
                        :options="form.salutation.options"
                        v-model="form.salutation.value"
                    >
                    </b-form-radio-group>
                </b-form-group>

                <b-form-group label="Titel:" label-for="title-field">
                    <b-input
                        type="text"
                        placeholder="Titel"
                        id="title-field"
                        v-model="form.title.value"
                        list="titlelist"
                    ></b-input>
                    <datalist id="titlelist">
                        <option value="Dr."></option>
                        <option value="Prof."></option>
                        <option value="Prof. Dr."></option>
                    </datalist>
                </b-form-group>
                <b-form-group label="Name:" label-for="name-fields" class="required">
                    <b-row>
                        <b-col>
                            <b-form-input
                                @change="setDirty('first_name')"
                                type="text"
                                v-model="form.first_name.value"
                                aria-label="Vorname"
                                placeholder="Vorname"
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validate('first_name')">
                                Der Vorname muss länger als 3 Zeichen sein.
                            </b-form-invalid-feedback>
                        </b-col>
                        <b-col>
                            <b-form-input
                                @change="setDirty('last_name')"
                                type="text"
                                v-model="form.last_name.value"
                                aria-label="Nachname"
                                placeholder="Nachname"
                                required
                            ></b-form-input>
                            <b-form-invalid-feedback :state="validate('last_name')">
                                Der Nachname muss länger als 3 Zeichen sein.
                            </b-form-invalid-feedback>
                        </b-col>
                    </b-row>
                </b-form-group>
                <b-row>
                    <b-col>
                        <b-form-group
                            label="E-Mail:"
                            label-for="mail-field"
                            class="required"
                            invalid-feedback="Bitte geben Sie eine gültige E-Mail-Adresse ein!"
                            :state="validate('email')"
                        >
                            <b-input
                                type="email"
                                id="mail-field"
                                placeholder="E-Mail"
                                v-model="form.email.value"
                                required
                                @change="setDirty('email')"
                            ></b-input>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group
                            label="Telefon:"
                            label-for="phone-field"
                            class="required"
                            invalid-feedback="Bitte geben Sie eine Telefonnummer ein."
                            :state="validate('phone')"
                        >
                            <b-input
                                type="tel"
                                id="phone-field"
                                placeholder="Telefon"
                                v-model="form.phone.value"
                                required
                                @change="setDirty('phone')"
                            ></b-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-form-group label="Adresse" label-size="lg" class="b">
                    <div class="bordered">
                        <b-form-group label="Straße und Hausnummer:" label-for="street">
                            <b-form-input
                                type="text"
                                aria-label="Street"
                                id="street"
                                v-model="form.street.value"
                                placeholder="Straße, Hausnummer"
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Postleitzahl und Ort:"
                            label-for="address-fields"
                        >
                            <b-row>
                                <b-col>
                                    <b-form-input
                                        type="text"
                                        v-model="form.postcode.value"
                                        aria-label="Postleitzahl"
                                        placeholder="Postleitzahl"
                                    ></b-form-input>
                                </b-col>
                                <b-col>
                                    <b-form-input
                                        type="text"
                                        v-model="form.city.value"
                                        aria-label="Ort"
                                        placeholder="Ort"
                                    ></b-form-input>
                                </b-col>
                            </b-row>
                        </b-form-group>
                    </div>
                </b-form-group>
                <b-form-group
                    label-cols-md="12"
                    :label="'Anzahl der Besuchenden: ' + form.visitor_count.value"
                    label-for="visitor-field"
                >
                    <div class="range-slider--wrapper">
                        <span>{{ form.visitor_count.min }}</span>
                        <b-input
                            type="range"
                            id="visitor-field"
                            :min="form.visitor_count.min"
                            :max="form.visitor_count.max"
                            required
                            v-model="form.visitor_count.value"
                        ></b-input>
                        <span>{{ form.visitor_count.max }}</span>
                    </div>
                </b-form-group>
                <b-form-group
                    label="Ihr Beratungswunsch:"
                    label-for="message-area"
                    message="Beschreibung der gewünschten Beratung"
                    class="required"
                    invalid-feedback="Bitte geben Sie eine Nachricht mit mindestens 8 Zeichen ein!"
                    :state="validate('message')"
                >
                    <b-form-textarea
                        placeholder="Ich interessiere mich besonders für ..."
                        rows="4"
                        id="message-area"
                        v-model="form.message.value"
                        required
                        @change="setDirty('message')"
                        minlength="8"
                    >
                    </b-form-textarea>
                </b-form-group>
                <b-form-group label="Wenn Sie eine Bilddatei zur Ansicht hochladen möchten:" label-for="file-upload">
                    <b-form-file
                        drop-placeholder="Dateien hier her ziehen..."
                        placeholder=""
                        v-model="form.images.files"
                        accept="image/*"
                        multiple
                        id="file-upload"
                    >
                        <template #placeholder>
                            <b-icon-cloud-upload font-scale="2"></b-icon-cloud-upload><br/>
                            <span>Hochzuladende Datei hier hinziehen oder <span style="text-decoration: underline">nach Datei suchen.</span></span>
                        </template>
                    </b-form-file>
                </b-form-group>
                <b-form-checkbox
                    v-model="form.privacy.value"
                    required
                    @change="setDirty('privacy')"
                >
                    Ich habe die
                    <a
                        target="_blank"
                        href="https://ruhe.de/datenschutz"
                        style="text-decoration: underline"
                    >Datenschutzvereinbarung</a
                    >
                    gelesen und akzeptiere die Verarbeitung meiner Daten.
                </b-form-checkbox>
                <b-form-invalid-feedback :state="validate('privacy')">
                    Bitte akzeptieren Sie die Datenschutzvereinbarung.
                </b-form-invalid-feedback>
                <b-row class="mt-2 form-btn-grp">
                    <b-col sm="4">
                        <b-button type="reset" size="md" variant="primary">
                            Abbrechen
                        </b-button>
                    </b-col>
                    <b-col sm="4"
                    >
                        <b-button
                            type="submit"
                            size="md"
                            variant="success"
                            :disabled="!form.privacy.value"
                        >
                            Senden
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            <p class="submission-notice pt-3 text-sm">ACHTUNG: Im Anschluss senden wir Ihnen eine E-Mail mit einem Link
                zu. Bitte klicken Sie den Link innerhalb der nächsten 6 Stunden, um Ihren Termineintrag zu
                bestätigen!</p>
        </b-container>
    </b-modal>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
    name: "AppointmentModal",
    data() {
        return {
            form: {
                isFollowup: {
                    value: false
                },
                consultant_id: {
                    value: null,
                },
                salutation: {
                    dirty: false,
                    options: [
                        {
                            text: "keine Anrede",
                            value: "d"
                        },
                        {
                            text: "Frau",
                            value: "w"
                        },
                        {
                            text: "Herr",
                            value: "m"
                        }
                    ],
                    value: "d"
                },
                appointmentTime: {
                    dirty: false,
                    value: "09:00"
                },
                title: {
                    dirty: false,
                    value: ""
                },
                first_name: {
                    dirty: false,
                    value: "",
                    validate() {
                        return this.dirty ? this.value.length > 3 : null;
                    }
                },
                last_name: {
                    dirty: false,
                    value: "",
                    validate() {
                        return this.dirty ? this.value.length > 3 : null;
                    }
                },
                email: {
                    dirty: false,
                    value: "",
                    validate() {
                        return this.dirty ? /^.+@.+\..+$/.test(this.value) : null;
                    }
                },
                phone: {
                    dirty: false,
                    value: "",
                    validate() {
                        return this.dirty ? this.value.length > 0 : null;
                    }
                },
                street: {
                    dirty: false,
                    value: ""
                },
                postcode: {
                    dirty: false,
                    value: ""
                },
                city: {
                    dirty: false,
                    value: ""
                },
                visitor_count: {
                    dirty: false,
                    value: 1,
                    min: 1,
                    max: 2
                },
                message: {
                    dirty: false,
                    value: "",
                    validate() {
                        return this.dirty ? this.value.length > 7 : null;
                    }
                },
                images: {
                    files:[]
                },
                privacy: {
                    dirty: false,
                    value: false,
                    validate() {
                        return this.dirty ? this.value : null;
                    }
                }
            },
            show: false
        };
    },
    computed: {
        ...mapGetters(["getSelectedDate",
            "getTimeslotsForDate",
            "isAuthenticated",
            "getAvailableConsultantsForDate",
            "getAllAppointmentsOnDay",
            "getCalendarDataForDate",
            "getAvailableConsultantsForTimeslotOnDate"
        ]),
        consultantOptions() {
            this.form.consultant_id.value = null;
            const result = [];
            result.push({text: "Kein Wunsch-Berater", value: null});
            if (this.getSelectedDate) {
                result.push(...this.getAvailableConsultantsForTimeslotOnDate(this.getSelectedDate, this.form.appointmentTime.value).map(mConsultant => {
                    return {
                        text: mConsultant.first_name + " " + mConsultant.last_name,
                        value: mConsultant.consultant_id
                    }
                }));
            }
            return result;
        },
        date() {
            return this.getSelectedDate
                ? ("0" + this.getSelectedDate.getDate()).slice(-2) +
                "." +
                ("0" + (this.getSelectedDate.getMonth() + 1)).slice(-2) +
                "." +
                this.getSelectedDate.getFullYear()
                : "";
        },
        appointmentTimeOptions() {

            if (this.calendarData === undefined
                || this.calendarData.timeslots === undefined) {
                return [];
            }

            return this.allowedTimeOptions.map(time => {
                return {
                    text: time.text,
                    value: time.value,
                    disabled: !(this.calendarData.timeslots[time.value].max > 0 && this.calendarData.timeslots[time.value].available > 0)
                }
            });
        },
        allowedTimeOptions() {
            return this.getTimeslotsForDate(this.getSelectedDate);
        },
        calendarData() {
            return this.getCalendarDataForDate(this.getSelectedDate);
        }
    },
    methods: {
        ...mapActions(["createAppointment"]),
        setDirty(fieldName) {
            this.form[fieldName].dirty = true;
        },
        validate(fieldName) {
            return this.form[fieldName].validate();
        },
        resetValuesAndClose() {
            this.form.appointmentTime.value = "09:00";
            this.form.salutation.value = "d";
            this.form.title.value = "";
            this.form.first_name.value = "";
            this.form.last_name.value = "";
            this.form.email.value = "";
            this.form.city.value = "";
            this.form.phone.value = "";
            this.form.street.value = "";
            this.form.postcode.value = "";
            this.form.visitor_count.value = 1;
            this.form.message.value = "";
            this.show = false;
            this.form.privacy.value = false;
            this.form.consultant_id.value = null;
            this.form.isFollowup.value = false;
            this.form.images.files = [];
        },
        sendAppointment() {
            if (!this.form.privacy.value) return;
            let appointObject = {
                time: this.form.appointmentTime.value,
                date: formatDate(this.getSelectedDate),
                gender: this.form.salutation.value,
                city: this.form.city.value,
                title: this.form.title.value,
                first_name: this.form.first_name.value,
                last_name: this.form.last_name.value,
                email: this.form.email.value,
                phone: this.form.phone.value,
                street: this.form.street.value,
                zipcode: this.form.postcode.value,
                visitor_count: this.form.visitor_count.value,
                message: this.form.message.value,
                consultant_id: this.form.consultant_id.value,
                is_followup: this.form.isFollowup.value,
                images:this.form.images.files
            };
            this.createAppointment({
                vm: this,
                payload: appointObject
            });
            this.resetValuesAndClose();
        }
    },

};
</script>

<style lang="scss">


.corona-hint {
    display: block;
    margin: 0 auto;
}

.submission-notice {
    font-weight: bold;
    color: #d10007;
}

.bordered {
    padding: 5px;
}

.range-slider--wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    span {
        display: block;
        padding: 5px;
    }
}

#appointment-modal {
    input,
    select,
    textarea {
        border: 1px #cecece;
        background: #f1f1f1;
        box-shadow: none;

        &#visitor-field {
            background: #fff;
        }
    }

    .form-control, .custom-select {
        padding: 0.5rem;
        height: 2.5rem;
    }


    .form-group {
        &.required {
            label {
                &::after {
                    content: " *";
                    color: red;
                    font-weight: bold;
                }
            }
        }

        .b-form-file{
            background: #f1f1f1;
            border: 2px dotted rgba(0,0,0,0.3);
            height:100px;
            .custom-file-input{
                &:focus ~ .custom-file-label{
                    border:0;
                    box-shadow:none;
                }
            }
            .custom-file-label{
                &::after{
                    display:none;
                }
                padding:0;
                text-align:center;
                top:20px;
                overflow: hidden;
            }
        }
    }

    .this-required {
        &::after {
            content: " *";
            color: red;
            font-weight: bold;
        }
    }

    .form-btn-grp {
        justify-content: space-between;

        button {
            width: 100%;
            margin-top: 1rem;
            @media screen and (min-width: 678px) {
                //width: auto;
            }
        }
    }
}

input[disabled][type="radio"] + .custom-control-label {
    &::before {
        background-color: #ccc !important;
    }

    text-decoration: line-through;
}

.modal-header {

    .modal-title {
        text-align: center;
        margin: auto;
    }

    button.close {
        margin-left: -16px;

    }
}
</style>
