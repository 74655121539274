<template>
<b-container class="content mb-3 py-3">
    <div class="title-container py-3 text-center">
        <h1>Verwaltung der Bewertungskriterien</h1>
        <ReviewCriteriaForm></ReviewCriteriaForm>
    </div>
    <review-criteria-list :review-criteria="getCriteria"></review-criteria-list>
</b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ReviewCriteriaList from "../components/Review/ReviewCriteriaList";
import ReviewCriteriaForm from "../components/Review/ReviewCriteriaForm";
export default {
    name: "ReviewCriteria",
    components:{
        ReviewCriteriaList,
        ReviewCriteriaForm
    },
    data()
    {
        return {

        }
    },
    methods:{
        ...mapActions(["fetchCriteria"]),
    },
    computed:{
      ...mapGetters(["getCriteria"])
    },
    mounted() {
        this.fetchCriteria();
    }
}
</script>

<style scoped lang="scss">
.title-container{
    position:relative;
}

</style>
